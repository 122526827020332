@import '../../../theme/colors.scss';
.wrapper {
  .paragraph {
    font-size: 16px;
    white-space: pre-wrap;
  }
  .createAt {
    color: #a5a4a4;
  }
}
