body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.BackButton_backBtn__3c3qg {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #03a9f4;
  align-items: center;
  border: 1px solid #03a9f4;
  cursor: pointer;
  margin-left: 10px; }
  .BackButton_backBtn__3c3qg .BackButton_icon__1qorD {
    margin-right: 5px; }
  .BackButton_backBtn__3c3qg:focus {
    outline: none; }
  .BackButton_backBtn__3c3qg:active {
    background-color: #03a9f4;
    color: #ffffff; }

.LoadingView_wrapper__swz_F {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Safari */ }
  .LoadingView_wrapper__swz_F .LoadingView_loader__TLDcy {
    border: 16px solid #ffffff;
    border-radius: 50%;
    border-top: 16px solid #03a9f4;
    width: 80px;
    height: 80px;
    -webkit-animation: LoadingView_spin__FXxZC 2s linear infinite;
    /* Safari */
    animation: LoadingView_spin__FXxZC 2s linear infinite; }

@-webkit-keyframes LoadingView_spin__FXxZC {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes LoadingView_spin__FXxZC {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Header_wrapper__BcmYn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: rgba(211, 211, 211, 0.548);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .Header_wrapper__BcmYn h2 {
    margin-bottom: 0px; }
  .Header_wrapper__BcmYn .Header_avatar__2OFT8 {
    text-transform: uppercase; }
    .Header_wrapper__BcmYn .Header_avatar__2OFT8:nth-child(4n) {
      background-color: red; }
    .Header_wrapper__BcmYn .Header_avatar__2OFT8:nth-child(4n + 1) {
      background-color: orange; }
    .Header_wrapper__BcmYn .Header_avatar__2OFT8:nth-child(4n + 2) {
      background-color: purple; }
    .Header_wrapper__BcmYn .Header_avatar__2OFT8:nth-child(4n + 3) {
      background-color: blue; }

.UserListContainer_wrapper__25uj3 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .UserListContainer_wrapper__25uj3 .UserListContainer_top__xfPw9 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .UserListContainer_wrapper__25uj3 .UserListContainer_top__xfPw9 .UserListContainer_searchbar__2MC2Y {
      flex: 1 1;
      height: 40px;
      border-radius: 20px;
      background-color: #ffffff;
      border: 1px solid #03a9f4;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px; }
      .UserListContainer_wrapper__25uj3 .UserListContainer_top__xfPw9 .UserListContainer_searchbar__2MC2Y:active {
        background-color: #ffffff; }
      .UserListContainer_wrapper__25uj3 .UserListContainer_top__xfPw9 .UserListContainer_searchbar__2MC2Y .UserListContainer_iconSearch__kdS_z {
        color: #03a9f4;
        font-size: 18px;
        margin-right: 5px; }
      .UserListContainer_wrapper__25uj3 .UserListContainer_top__xfPw9 .UserListContainer_searchbar__2MC2Y input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-style: none; }
        .UserListContainer_wrapper__25uj3 .UserListContainer_top__xfPw9 .UserListContainer_searchbar__2MC2Y input:focus {
          outline: none; }
  .UserListContainer_wrapper__25uj3 .UserListContainer_actionDelete__2RF0s {
    margin: 0px 5px;
    color: #b71c1c; }
    .UserListContainer_wrapper__25uj3 .UserListContainer_actionDelete__2RF0s:active {
      opacity: 0.5; }
  .UserListContainer_wrapper__25uj3 img {
    width: 50px;
    height: 50px;
    border-radius: 50%; }

.UserViewContainer_wrapper__Z3LJQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-size: 16px; }
  .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_top__3HfPb {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px; }
    .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_top__3HfPb .UserViewContainer_btnBack__2Hzs9 {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_top__3HfPb .UserViewContainer_btnBack__2Hzs9 .UserViewContainer_icon__1GTLX {
        margin-right: 5px; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_top__3HfPb .UserViewContainer_btnBack__2Hzs9:focus {
        outline: none; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_top__3HfPb .UserViewContainer_btnBack__2Hzs9:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .UserViewContainer_wrapper__Z3LJQ img {
    width: 150px;
    height: 150px;
    border-radius: 50%; }
  .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_name__3AkQe {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 'bold'; }
  .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_email__2ddlM {
    margin-top: 10px; }
  .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_socialContainer__7P3E0 {
    width: calc(100% - 40px);
    padding: 20px;
    border: 1px solid #dddddd;
    margin-top: 20px; }
    .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_socialContainer__7P3E0 .UserViewContainer_socialItem__2cwVr {
      margin: 10px 0px; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_socialContainer__7P3E0 .UserViewContainer_socialItem__2cwVr i {
        width: 30px;
        font-size: 18px;
        margin-right: 10px; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_socialContainer__7P3E0 .UserViewContainer_socialItem__2cwVr a {
        color: #03a9f4; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_socialContainer__7P3E0 .UserViewContainer_socialItem__2cwVr span {
        color: #b71c1c; }
  .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_detailsContainer__3g9q5 {
    width: calc(100% - 40px);
    padding: 20px;
    border: 1px solid #dddddd;
    margin-top: 20px; }
    .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_detailsContainer__3g9q5 .UserViewContainer_detailsItem__1iLaA {
      display: flex;
      margin-bottom: 10px; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_detailsContainer__3g9q5 .UserViewContainer_detailsItem__1iLaA span {
        width: 100px;
        font-size: 14px;
        color: #555555; }
      .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_detailsContainer__3g9q5 .UserViewContainer_detailsItem__1iLaA .UserViewContainer_detailsInfo__2qgwU {
        flex: 1 1;
        font-size: 16px; }
  .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_featuredVideoContainer__1b8IM {
    width: calc(100% - 40px);
    padding: 20px;
    border: 1px solid #dddddd;
    margin-top: 20px; }
    .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_featuredVideoContainer__1b8IM video {
      width: 300px;
      height: 200px;
      margin-top: 10px; }
    .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_featuredVideoContainer__1b8IM span {
      margin-top: 10px;
      color: #b71c1c; }
  .UserViewContainer_wrapper__Z3LJQ .UserViewContainer_btnBack__2Hzs9 {
    width: 100px;
    margin-top: 20px; }

.UserHistory_wrapper__2BSjO {
  width: calc(100% - 40px);
  margin-top: 20px; }

.ClientListContainer_wrapper__3fR-E {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW .ClientListContainer_searchbar__23pfH {
      flex: 1 1;
      height: 40px;
      border-radius: 20px;
      background-color: #ffffff;
      border: 1px solid #03a9f4;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      margin-right: 10px; }
      .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW .ClientListContainer_searchbar__23pfH:active {
        background-color: #ffffff; }
      .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW .ClientListContainer_searchbar__23pfH .ClientListContainer_iconSearch__lCT1U {
        color: #03a9f4;
        font-size: 18px;
        margin-right: 5px; }
      .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW .ClientListContainer_searchbar__23pfH input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-style: none; }
        .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW .ClientListContainer_searchbar__23pfH input:focus {
          outline: none; }
    .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW .ClientListContainer_ml-10__3xjkP {
      margin-left: 10px; }
    .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW div {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer; }
      .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW div .ClientListContainer_icon__3mYqC {
        margin-right: 5px; }
      .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW div:focus {
        outline: none; }
      .ClientListContainer_wrapper__3fR-E .ClientListContainer_top__3v5jW div:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .ClientListContainer_wrapper__3fR-E table {
    width: 100%;
    border-collapse: collapse; }
  .ClientListContainer_wrapper__3fR-E .ClientListContainer_header__32Y31 {
    background: #03a9f4; }
  .ClientListContainer_wrapper__3fR-E th {
    height: 40px;
    padding: 10px 10px;
    color: #ffffff; }
  .ClientListContainer_wrapper__3fR-E td {
    padding: 10px 10px; }
  .ClientListContainer_wrapper__3fR-E .ClientListContainer_org__3ILb8 {
    text-decoration: underline;
    color: #03a9f4;
    cursor: pointer; }

.ClientAddContainer_wrapper__2XTlN {
  display: flex;
  flex-direction: column;
  padding: 20px; }
  .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_top__2m3dV {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_container__1BWNU {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 50px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_container__1BWNU h2 {
      font-weight: 600; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_container__1BWNU .ClientAddContainer_title__3nAkb {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_container__1BWNU .ClientAddContainer_title__3nAkb .ClientAddContainer_iconRemove__2Om7B {
        font-size: 32px;
        color: #b71c1c; }
        .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_container__1BWNU .ClientAddContainer_title__3nAkb .ClientAddContainer_iconRemove__2Om7B:active {
          opacity: 0.5; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_container__1BWNU .ClientAddContainer_btnAddMore__39Y1D {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      cursor: pointer; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_container__1BWNU .ClientAddContainer_btnAddMore__39Y1D:active {
        opacity: 0.5; }
  .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA span {
      width: 200px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA input {
      height: 40px;
      width: 300px;
      font-size: 16px;
      padding: 0 20px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_codeAction__z3_9- {
      width: 260px;
      display: flex;
      justify-content: space-between; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_codeAction__z3_9- button {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 12px;
        line-height: 40px;
        font-weight: 600;
        box-shadow: none; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_codeAction__z3_9- button:first-of-type {
        margin: 0 10px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_file__3g7ko {
      display: none; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb {
      width: 342px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btn__3tdQ3, .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnPrimary__3YPg9, .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnPrimaryDisabled__1a28j, .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnDanger__3rbLV, .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnDangerDisabled__X2odo {
        flex: 1 1;
        height: 40px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        align-items: center;
        cursor: pointer; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnPrimary__3YPg9 {
        color: #03a9f4;
        border: 1px solid #03a9f4;
        margin-right: 10px;
        text-decoration: none; }
        .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnPrimary__3YPg9:active {
          background-color: #03a9f4;
          color: #ffffff; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnPrimaryDisabled__1a28j {
        color: #dddddd;
        border: 1px solid #dddddd;
        margin-right: 10px; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnDanger__3rbLV {
        color: #b71c1c;
        border: 1px solid #b71c1c; }
        .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnDanger__3rbLV:active {
          background-color: #b71c1c;
          color: #ffffff; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_participantBtnGroup__1aPMb .ClientAddContainer_btnDangerDisabled__X2odo {
        color: #dddddd;
        border: 1px solid #dddddd; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_nameInput__2OCp1 {
      width: 207px;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      margin-right: 10px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_emailInput__2Ut3v {
      width: 207px;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      margin-right: 10px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_btnAdd__3L5Gv {
      width: 105px;
      height: 40px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      align-items: center;
      color: #03a9f4;
      border: 1px solid #03a9f4;
      margin-right: 10px;
      cursor: pointer; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_inputItem__25WXA .ClientAddContainer_btnAdd__3L5Gv:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_btnGroup__oODb7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_btnGroup__oODb7 .ClientAddContainer_btnSave__PvjrT {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer;
      margin-right: 50px; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_btnGroup__oODb7 .ClientAddContainer_btnSave__PvjrT:active {
        background-color: #03a9f4;
        color: #ffffff; }
    .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_btnGroup__oODb7 .ClientAddContainer_btnCancel__2oLWR {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #b71c1c;
      align-items: center;
      border: 1px solid #b71c1c;
      cursor: pointer; }
      .ClientAddContainer_wrapper__2XTlN .ClientAddContainer_btnGroup__oODb7 .ClientAddContainer_btnCancel__2oLWR:active {
        background-color: #b71c1c;
        color: #ffffff; }
  .ClientAddContainer_wrapper__2XTlN table {
    border-collapse: collapse;
    margin-bottom: 10px; }
  .ClientAddContainer_wrapper__2XTlN th {
    padding: 10px 10px;
    background-color: #eee; }
  .ClientAddContainer_wrapper__2XTlN td {
    padding: 10px 10px; }
  .ClientAddContainer_wrapper__2XTlN table,
  .ClientAddContainer_wrapper__2XTlN th,
  .ClientAddContainer_wrapper__2XTlN td {
    border: 1px solid #dddddd;
    text-align: center; }

.ClientEditContainer_wrapper__3Hp1h {
  display: flex;
  flex-direction: column;
  padding: 20px; }
  .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_top__3hPpK {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_container__39Yjm {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 50px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_container__39Yjm h2 {
      font-weight: 600; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_container__39Yjm .ClientEditContainer_title__1AWp_ {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_container__39Yjm .ClientEditContainer_title__1AWp_ .ClientEditContainer_iconRemove__2RuCS {
        font-size: 32px;
        color: #b71c1c; }
        .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_container__39Yjm .ClientEditContainer_title__1AWp_ .ClientEditContainer_iconRemove__2RuCS:active {
          opacity: 0.5; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_container__39Yjm .ClientEditContainer_btnAddMore__1GdFT {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      cursor: pointer; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_container__39Yjm .ClientEditContainer_btnAddMore__1GdFT:active {
        opacity: 0.5; }
  .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 span {
      width: 200px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 input {
      height: 40px;
      width: 300px;
      font-size: 16px;
      padding: 0 20px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_codeAction__2kH22 {
      width: 260px;
      display: flex;
      justify-content: space-between; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_codeAction__2kH22 button {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 12px;
        line-height: 40px;
        font-weight: 600;
        box-shadow: none; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_codeAction__2kH22 button:first-of-type {
        margin: 0 10px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_file__2mU0G {
      display: none; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx {
      width: 342px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btn__tJ8Yw, .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnPrimary__3C8YD, .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnPrimaryDisabled__3V7H4, .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnDanger__1-kJA, .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnDangerDisabled__1ryNq {
        flex: 1 1;
        height: 40px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        align-items: center;
        cursor: pointer; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnPrimary__3C8YD {
        color: #03a9f4;
        border: 1px solid #03a9f4;
        margin-right: 10px;
        text-decoration: none; }
        .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnPrimary__3C8YD:active {
          background-color: #03a9f4;
          color: #ffffff; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnPrimaryDisabled__3V7H4 {
        color: #dddddd;
        border: 1px solid #dddddd;
        margin-right: 10px; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnDanger__1-kJA {
        color: #b71c1c;
        border: 1px solid #b71c1c; }
        .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnDanger__1-kJA:active {
          background-color: #b71c1c;
          color: #ffffff; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_participantBtnGroup__1H3zx .ClientEditContainer_btnDangerDisabled__1ryNq {
        color: #dddddd;
        border: 1px solid #dddddd; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_nameInput__1vujO {
      width: 207px;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      margin-right: 10px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_emailInput__3EDvB {
      width: 207px;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      margin-right: 10px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_btnAdd__3siQn {
      width: 105px;
      height: 40px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      align-items: center;
      color: #03a9f4;
      border: 1px solid #03a9f4;
      margin-right: 10px;
      cursor: pointer; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_inputItem__1atD5 .ClientEditContainer_btnAdd__3siQn:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_btnGroup__1MXM_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_btnGroup__1MXM_ .ClientEditContainer_btnSave__Sa0jD {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer;
      margin-right: 50px; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_btnGroup__1MXM_ .ClientEditContainer_btnSave__Sa0jD:active {
        background-color: #03a9f4;
        color: #ffffff; }
    .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_btnGroup__1MXM_ .ClientEditContainer_btnCancel__1qSBe {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #b71c1c;
      align-items: center;
      border: 1px solid #b71c1c;
      cursor: pointer; }
      .ClientEditContainer_wrapper__3Hp1h .ClientEditContainer_btnGroup__1MXM_ .ClientEditContainer_btnCancel__1qSBe:active {
        background-color: #b71c1c;
        color: #ffffff; }
  .ClientEditContainer_wrapper__3Hp1h table {
    border-collapse: collapse;
    margin-bottom: 10px; }
  .ClientEditContainer_wrapper__3Hp1h th {
    padding: 10px 10px;
    background-color: #eee; }
  .ClientEditContainer_wrapper__3Hp1h td {
    padding: 10px 10px; }
  .ClientEditContainer_wrapper__3Hp1h table,
  .ClientEditContainer_wrapper__3Hp1h th,
  .ClientEditContainer_wrapper__3Hp1h td {
    border: 1px solid #dddddd;
    text-align: center; }

.CampaignListContainer_wrapper__3sK8Y {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
    .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D {
      flex: 1 1;
      margin-right: 20px; }
      .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchbar__1GNqq {
        height: 40px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #03a9f4;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px; }
        .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchbar__1GNqq:active {
          background-color: #ffffff; }
        .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchbar__1GNqq .CampaignListContainer_iconSearch__3tR7n {
          color: #03a9f4;
          font-size: 18px;
          margin-right: 5px; }
        .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchbar__1GNqq input {
          width: 100%;
          height: 40px;
          font-size: 16px;
          border-style: none; }
          .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchbar__1GNqq input:focus {
            outline: none; }
      .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchfilters__3BKB- {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchfilters__3BKB- .CampaignListContainer_filter__xJezI {
          flex: 1 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px; }
          .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchfilters__3BKB- .CampaignListContainer_filter__xJezI .CampaignListContainer_select__1Ghs_ {
            width: 80%;
            height: 40px;
            z-index: 10; }
            .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchfilters__3BKB- .CampaignListContainer_filter__xJezI .CampaignListContainer_select__1Ghs_ span {
              width: 0px;
              background-color: #ffffff; }
          .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_top__1Bt7- .CampaignListContainer_searchContainer__3wW-D .CampaignListContainer_searchfilters__3BKB- .CampaignListContainer_filter__xJezI input {
            margin-right: 5px; }
  .CampaignListContainer_wrapper__3sK8Y table {
    width: 100%;
    border-collapse: collapse; }
    .CampaignListContainer_wrapper__3sK8Y table .CampaignListContainer_action__30wdr > i {
      font-size: 20px;
      cursor: pointer; }
  .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_header__3qSIt {
    background: #03a9f4; }
  .CampaignListContainer_wrapper__3sK8Y th {
    height: 40px;
    padding: 10px 10px;
    color: #ffffff; }
  .CampaignListContainer_wrapper__3sK8Y td {
    padding: 10px 10px; }
  .CampaignListContainer_wrapper__3sK8Y table,
  .CampaignListContainer_wrapper__3sK8Y th,
  .CampaignListContainer_wrapper__3sK8Y td {
    border: 1px solid #dddddd;
    text-align: center; }
  .CampaignListContainer_wrapper__3sK8Y .CampaignListContainer_name__3A3UH {
    text-decoration: underline;
    color: #03a9f4;
    cursor: pointer; }

.CampaignListContainer_btnAddOpen__31IKQ {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  color: #03a9f4;
  align-items: center;
  border: 1px solid #03a9f4;
  cursor: pointer;
  margin-right: 10px; }
  .CampaignListContainer_btnAddOpen__31IKQ .CampaignListContainer_icon__1_ca2 {
    margin-right: 5px; }
  .CampaignListContainer_btnAddOpen__31IKQ:focus {
    outline: none; }
  .CampaignListContainer_btnAddOpen__31IKQ:active {
    background-color: #03a9f4;
    color: #ffffff; }

.CampaignListContainer_btnAdd__1Tqka {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  color: #03a9f4;
  align-items: center;
  border: 1px solid #03a9f4;
  cursor: pointer;
  margin-right: 10px; }
  .CampaignListContainer_btnAdd__1Tqka .CampaignListContainer_icon__1_ca2 {
    margin-right: 5px; }
  .CampaignListContainer_btnAdd__1Tqka:focus {
    outline: none; }
  .CampaignListContainer_btnAdd__1Tqka:active {
    background-color: #03a9f4;
    color: #ffffff; }

.CampaignListContainer_modalHeader__1EF-2 {
  margin: 8px 0px; }

.CampaignListContainer_modalTitle__FfKHw {
  font-size: 18px;
  font-weight: 500; }

.CampaignListContainer_modalContent__1ApBm {
  display: flex;
  justify-content: space-around; }

.se-placeholder,
.sun-editor-editable *,
.se-wrapper-inner {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
  -webkit-font-smoothing: antialiased; }

.se-placeholder {
  font-size: 16px !important; }

.sun-editor-editable {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65); }

.se-dialog .se-dialog-tabs {
  display: none; }

.se-dialog .se-dialog-form .se-dialog-form-files > input {
  height: auto !important;
  padding: 3px !important; }

.se-dialog .se-dialog-form .se-dialog-files-edge-button {
  display: none; }

.se-dialog .se-dialog-form .se-link-preview {
  display: none !important; }

.se-dialog .se-dialog-image ._se_tab_content .se-dialog-body > div:first-child {
  border-width: 0px !important; }

.se-dialog .se-dialog-image ._se_tab_content .se-dialog-body > .se-dialog-form:nth-child(2), .se-dialog .se-dialog-image ._se_tab_content .se-dialog-body > .se-dialog-form:nth-child(4) {
  display: none !important; }

.se-dialog .se-dialog-body > .se-dialog-form-footer > label:nth-child(2) {
  display: none !important; }

.se-dialog .se-dialog-image ._se_tab_content .se-dialog-body > div:first-child {
  border-width: 0px !important; }

.se-dialog .se-dialog-image ._se_tab_content .se-dialog-body > .se-dialog-form:nth-child(2), .se-dialog .se-dialog-image ._se_tab_content .se-dialog-body > .se-dialog-form:nth-child(4) {
  display: none !important; }

.se-dialog .se-dialog-body > .se-dialog-form-footer > label:nth-child(2) {
  display: none !important; }

.CampaignAddContainer_wrapper__1XUQd {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_top__1A9lm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_info__1E7Zf {
    border: 1px solid #dddddd;
    padding: 20px 10px;
    margin-bottom: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_info__1E7Zf .CampaignAddContainer_title__1CB9G {
      grid-column: 1 / span 2; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_info__1E7Zf .CampaignAddContainer_typeIsChat__22ghe {
      grid-column: 2 / span 1; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_info__1E7Zf .CampaignAddContainer_textareaItem__igqvp {
      grid-column: 1 / span 2;
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_info__1E7Zf .CampaignAddContainer_textareaItem__igqvp > span {
        width: 150px;
        min-width: 150px;
        text-align: center;
        margin-top: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_info__1E7Zf .CampaignAddContainer_textareaItem__igqvp .CampaignAddContainer_textContainer__3lIZU {
        flex: 1 1;
        height: 100px;
        padding: 10px 20px;
        border: 1px solid #dddddd;
        overflow-y: auto;
        word-break: break-all; }
  .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_inputItem__3vtUR {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_inputItem__3vtUR > span {
      width: 150px;
      min-width: 150px;
      text-align: center; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_inputItem__3vtUR input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_inputItem__3vtUR input:disabled {
        background-color: #eeeeee; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_inputItem__3vtUR .CampaignAddContainer_select__32QIL {
      height: 40px;
      flex: 1 1; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_inputItem__3vtUR .CampaignAddContainer_select__32QIL span {
        width: 0px;
        background-color: #ffffff; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_inputItem__3vtUR .CampaignAddContainer_datepicker__10aRI {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
  .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_textReview__2bCx3 {
    padding-left: 150px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_textReview__2bCx3 .CampaignAddContainer_note__QQPIF {
      display: flex;
      margin-right: 10px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_textReview__2bCx3 .CampaignAddContainer_title__1CB9G {
      display: inline;
      margin-right: 10px;
      font-weight: bold; }
  .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB span {
      margin-top: 10px;
      width: 150px;
      text-align: center; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB .CampaignAddContainer_logoContainer__2IFBh {
      flex: 1 1; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB .CampaignAddContainer_logoContainer__2IFBh .CampaignAddContainer_file__yWpKB {
        display: none; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB .CampaignAddContainer_logoContainer__2IFBh .CampaignAddContainer_upload__3zBvc {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB .CampaignAddContainer_logoContainer__2IFBh .CampaignAddContainer_upload__3zBvc .CampaignAddContainer_btnUpload__3Ro2a {
          flex: 1 1;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          margin-bottom: 10px;
          cursor: pointer; }
          .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB .CampaignAddContainer_logoContainer__2IFBh .CampaignAddContainer_upload__3zBvc .CampaignAddContainer_btnUpload__3Ro2a .CampaignAddContainer_icon__5Vzdo {
            margin-right: 5px; }
          .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB .CampaignAddContainer_logoContainer__2IFBh .CampaignAddContainer_upload__3zBvc .CampaignAddContainer_btnUpload__3Ro2a:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_logoItem__36iIB .CampaignAddContainer_logoContainer__2IFBh span {
        width: 100%;
        margin-top: 10px; }
  .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 20px;
    cursor: initial; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_questionTitle__3Oa9Z {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_questionTitle__3Oa9Z > h2 {
        margin-right: 10px;
        margin-bottom: 0px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_questionTitle__3Oa9Z > span {
        cursor: pointer; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_title__1CB9G {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_title__1CB9G i {
        font-size: 25px;
        color: #b71c1c; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_title__1CB9G i:active {
          opacity: 0.5; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_qtypeContainer__2Jj-s {
      display: flex;
      grid-gap: 20px;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
      flex-wrap: wrap; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_qtypeContainer__2Jj-s .CampaignAddContainer_qtypeRadio__2Y-wk {
        flex: 1 1;
        display: flex;
        align-items: center;
        height: 30px;
        cursor: pointer;
        white-space: nowrap; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_qtypeContainer__2Jj-s .CampaignAddContainer_qtypeRadio__2Y-wk input {
          margin-right: 10px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_inputItem__3vtUR {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_inputItem__3vtUR > span {
        width: 150px;
        min-width: 150px;
        text-align: left; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_inputItem__3vtUR input {
        flex: 1 1;
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
        border: 1px solid #dddddd; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_inputItem__3vtUR input:disabled {
          background-color: #eeeeee; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh span {
        width: 150px;
        margin-top: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_localMedia__7SZyc .CampaignAddContainer_file__yWpKB {
        display: none; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_localMedia__7SZyc .CampaignAddContainer_upload__3zBvc {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_localMedia__7SZyc .CampaignAddContainer_upload__3zBvc .CampaignAddContainer_btnUpload__3Ro2a {
          width: 300px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          cursor: pointer; }
          .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_localMedia__7SZyc .CampaignAddContainer_upload__3zBvc .CampaignAddContainer_btnUpload__3Ro2a:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_localMedia__7SZyc .CampaignAddContainer_mediaView__2bgo1 {
        margin-top: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_localMedia__7SZyc .CampaignAddContainer_nomedia__i6nYp {
        width: 100%;
        margin-top: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_or__3JMDm {
        margin: 0px 20px;
        margin-top: 10px;
        font-weight: bold; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_youtubeMedia__2Ti9W .CampaignAddContainer_btnUpload__3Ro2a {
        width: 300px;
        height: 40px;
        border-radius: 1px;
        font-size: 16px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        background-color: #03a9f4;
        align-items: center;
        border: 1px solid #03a9f4;
        cursor: pointer; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_youtubeMedia__2Ti9W .CampaignAddContainer_btnUpload__3Ro2a:active {
          background-color: #ffffff;
          color: #03a9f4; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_mediaContainer__1dCgh .CampaignAddContainer_youtubeMedia__2Ti9W .CampaignAddContainer_youtubeView__3O0em {
        margin-top: 10px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_userMediaOptionsContainer__2LNCx {
      display: flex;
      align-items: center; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_userMediaRequired__Et4SK,
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_userMediaShowHide__2GcOx {
      height: 40px;
      display: flex;
      align-items: center; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_userMediaRequired__Et4SK span,
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_userMediaShowHide__2GcOx span {
        margin-right: 20px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_userMediaRequired__Et4SK {
      margin-left: 20px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 span {
        width: 150px;
        margin-top: 10px; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div {
        flex: 1 1; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_answerItem__26fmf {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_answerItem__26fmf input {
            flex: 1 1;
            height: 40px;
            font-size: 16px;
            padding: 0 20px;
            border: 1px solid #dddddd;
            margin-bottom: 10px; }
            .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_answerItem__26fmf input:disabled {
              background-color: #eeeeee; }
          .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_answerItem__26fmf span {
            width: 30px;
            margin: 0 5px 10px 5px;
            text-align: right; }
            .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_answerItem__26fmf span i {
              font-size: 24px;
              color: #b71c1c; }
              .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_answerItem__26fmf span i:active {
                opacity: 0.5; }
        .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_btnAdd__1Y-VM {
          width: 100px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          color: #03a9f4;
          background-color: #ffffff;
          border: 1px solid #03a9f4;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_btnAdd__1Y-VM i {
            margin-right: 5px; }
          .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_answerContainer__9zxj9 div .CampaignAddContainer_btnAdd__1Y-VM:active {
            background-color: #03a9f4;
            color: #ffffff; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_btnAddMore__3Ymxl {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      cursor: pointer; }
      .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_questionContainer__3-FLg .CampaignAddContainer_btnAddMore__3Ymxl:active {
        opacity: 0.5; }
  .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_btnGroup__2_k2v {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 20px; }
    .CampaignAddContainer_wrapper__1XUQd .CampaignAddContainer_btnGroup__2_k2v .CampaignAddContainer_btn__1g1Vq {
      width: 100px;
      margin: 0px 10px; }

.CampaignAddContainer_clipListItemDragging__2SdTJ {
  z-index: 6666;
  background-color: #eeeeee; }

.MediaItem_wrapper__3w4Mg .MediaItem_mediaView__2k7tN {
  position: relative;
  margin: auto; }
  @media (max-width: 576px) {
    .MediaItem_wrapper__3w4Mg .MediaItem_mediaView__2k7tN {
      width: 100%; } }
  .MediaItem_wrapper__3w4Mg .MediaItem_mediaView__2k7tN .MediaItem_media__3hFhW {
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16); }
    @media (max-width: 576px) {
      .MediaItem_wrapper__3w4Mg .MediaItem_mediaView__2k7tN .MediaItem_media__3hFhW {
        width: 100%; } }
  .MediaItem_wrapper__3w4Mg .MediaItem_mediaView__2k7tN .MediaItem_iconRemove__2PU32 {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 30px;
    cursor: pointer; }
    .MediaItem_wrapper__3w4Mg .MediaItem_mediaView__2k7tN .MediaItem_iconRemove__2PU32 i {
      font-size: 30px;
      color: #b71c1c; }
      .MediaItem_wrapper__3w4Mg .MediaItem_mediaView__2k7tN .MediaItem_iconRemove__2PU32 i:active {
        opacity: 0.5; }

.CampaignAddOpenContainter_wrapper__POzv4 {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_top__1_FoN {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_tableSpace__1_Dtc {
    padding: 10px 10px;
    border: 1px solid #dddddd;
    margin-bottom: 50px;
    width: 100%; }
  .CampaignAddOpenContainter_wrapper__POzv4 table {
    width: 100%; }
    .CampaignAddOpenContainter_wrapper__POzv4 table.CampaignAddOpenContainter_tableSmall__aX0b1 {
      width: 50%; }
    .CampaignAddOpenContainter_wrapper__POzv4 table h2 {
      font-weight: 600; }
    .CampaignAddOpenContainter_wrapper__POzv4 table td {
      flex: 1 1;
      margin-left: 10px;
      margin-right: 10px;
      width: 50%; }
    .CampaignAddOpenContainter_wrapper__POzv4 table .CampaignAddOpenContainter_textareaItem__2ywXP {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 10px;
      min-height: 150px; }
      .CampaignAddOpenContainter_wrapper__POzv4 table .CampaignAddOpenContainter_textareaItem__2ywXP > span {
        width: 150px;
        min-width: 150px;
        text-align: center;
        margin-top: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 table .CampaignAddOpenContainter_textareaItem__2ywXP .CampaignAddOpenContainter_textContainer__12VLG {
        flex: 1 1;
        height: 100px;
        padding: 10px 20px;
        border: 1px solid #dddddd;
        overflow-y: auto;
        word-break: break-all; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_height40p__3dUQ5 {
    height: 40px; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_inputItem__15l4I {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_inputItem__15l4I > span {
      width: 150px;
      text-align: center; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_inputItem__15l4I input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_inputItem__15l4I input:disabled {
        background-color: #eeeeee; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_inputItem__15l4I .CampaignAddOpenContainter_select__OQWJR {
      height: 40px;
      flex: 1 1; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_inputItem__15l4I .CampaignAddOpenContainter_select__OQWJR span {
        width: 0px;
        background-color: #ffffff; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_inputItem__15l4I .CampaignAddOpenContainter_datepicker__1w6x9 {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_requiredField__1jPM9 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 150px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_requiredField__1jPM9 .CampaignAddOpenContainter_name__3aO3R span,
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_requiredField__1jPM9 .CampaignAddOpenContainter_email__27gV- span {
      margin-left: 10px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_requiredField__1jPM9 .CampaignAddOpenContainter_name__3aO3R {
      margin-right: 10px; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_textReview__cdi-o {
    padding-left: 150px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_textReview__cdi-o .CampaignAddOpenContainter_note__RgjRr {
      display: flex;
      margin-right: 10px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_textReview__cdi-o .CampaignAddOpenContainter_title__32Prg {
      display: inline;
      margin-right: 10px;
      font-weight: bold; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR span {
      margin-top: 10px;
      width: 150px;
      text-align: center; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR .CampaignAddOpenContainter_logoContainer__2A4Su {
      flex: 1 1; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR .CampaignAddOpenContainter_logoContainer__2A4Su .CampaignAddOpenContainter_file__2i_CG {
        display: none; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR .CampaignAddOpenContainter_logoContainer__2A4Su .CampaignAddOpenContainter_upload__10u3t {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR .CampaignAddOpenContainter_logoContainer__2A4Su .CampaignAddOpenContainter_upload__10u3t .CampaignAddOpenContainter_btnUpload__1G1Zt {
          flex: 1 1;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          margin-bottom: 10px;
          cursor: pointer; }
          .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR .CampaignAddOpenContainter_logoContainer__2A4Su .CampaignAddOpenContainter_upload__10u3t .CampaignAddOpenContainter_btnUpload__1G1Zt .CampaignAddOpenContainter_icon__3eO2T {
            margin-right: 5px; }
          .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR .CampaignAddOpenContainter_logoContainer__2A4Su .CampaignAddOpenContainter_upload__10u3t .CampaignAddOpenContainter_btnUpload__1G1Zt:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_logoItem__c4adR .CampaignAddOpenContainter_logoContainer__2A4Su span {
        width: 100%;
        margin-top: 10px; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 20px;
    cursor: initial; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_questionTitle__2Pc7v {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_questionTitle__2Pc7v > h2 {
        margin-right: 10px;
        margin-bottom: 0px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_questionTitle__2Pc7v > span {
        cursor: pointer; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_title__32Prg {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_title__32Prg i {
        font-size: 25px;
        color: #b71c1c; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_title__32Prg i:active {
          opacity: 0.5; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_qtypeContainer__3Q8I6 {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_qtypeContainer__3Q8I6 .CampaignAddOpenContainter_qtypeRadio__2Rj2I {
        flex: 1 1;
        display: flex;
        align-items: center;
        height: 30px;
        cursor: pointer; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_qtypeContainer__3Q8I6 .CampaignAddOpenContainter_qtypeRadio__2Rj2I input {
          margin-right: 10px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_inputItem__15l4I {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_inputItem__15l4I > span {
        width: 150px;
        text-align: left; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_inputItem__15l4I input {
        flex: 1 1;
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
        border: 1px solid #dddddd; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_inputItem__15l4I input:disabled {
          background-color: #eeeeee; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa span {
        width: 150px;
        margin-top: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_localMedia__3JtB1 .CampaignAddOpenContainter_file__2i_CG {
        display: none; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_localMedia__3JtB1 .CampaignAddOpenContainter_upload__10u3t {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_localMedia__3JtB1 .CampaignAddOpenContainter_upload__10u3t .CampaignAddOpenContainter_btnUpload__1G1Zt {
          width: 300px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          cursor: pointer; }
          .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_localMedia__3JtB1 .CampaignAddOpenContainter_upload__10u3t .CampaignAddOpenContainter_btnUpload__1G1Zt:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_localMedia__3JtB1 .CampaignAddOpenContainter_mediaView__3UM9F {
        margin-top: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_localMedia__3JtB1 .CampaignAddOpenContainter_nomedia__v610y {
        width: 100%;
        margin-top: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_or__3AYX1 {
        margin: 0px 20px;
        margin-top: 10px;
        font-weight: bold; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_youtubeMedia__3KPax .CampaignAddOpenContainter_btnUpload__1G1Zt {
        width: 300px;
        height: 40px;
        border-radius: 1px;
        font-size: 16px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        background-color: #03a9f4;
        align-items: center;
        border: 1px solid #03a9f4;
        cursor: pointer; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_youtubeMedia__3KPax .CampaignAddOpenContainter_btnUpload__1G1Zt:active {
          background-color: #ffffff;
          color: #03a9f4; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_mediaContainer__KWMYa .CampaignAddOpenContainter_youtubeMedia__3KPax .CampaignAddOpenContainter_youtubeView__423J- {
        margin-top: 10px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_userMediaOptionsContainer__1ZLur {
      display: flex;
      align-items: center; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_userMediaRequired__36UFp,
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_userMediaShowHide__1boJL {
      height: 40px;
      display: flex;
      align-items: center; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_userMediaRequired__36UFp span,
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_userMediaShowHide__1boJL span {
        margin-right: 20px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_userMediaRequired__36UFp {
      margin-left: 20px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb span {
        width: 150px;
        margin-top: 10px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div {
        flex: 1 1; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_answerItem__6chqt {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_answerItem__6chqt input {
            flex: 1 1;
            height: 40px;
            font-size: 16px;
            padding: 0 20px;
            border: 1px solid #dddddd;
            margin-bottom: 10px; }
            .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_answerItem__6chqt input:disabled {
              background-color: #eeeeee; }
          .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_answerItem__6chqt span {
            width: 30px;
            margin: 0 5px 10px 5px;
            text-align: right; }
            .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_answerItem__6chqt span i {
              font-size: 24px;
              color: #b71c1c; }
              .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_answerItem__6chqt span i:active {
                opacity: 0.5; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_btnAdd__3A14n {
          width: 100px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          color: #03a9f4;
          background-color: #ffffff;
          border: 1px solid #03a9f4;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_btnAdd__3A14n i {
            margin-right: 5px; }
          .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_answerContainer__QiQJb div .CampaignAddOpenContainter_btnAdd__3A14n:active {
            background-color: #03a9f4;
            color: #ffffff; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_btnAddMore__3g24i {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      cursor: pointer; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_questionContainer__PhiZh .CampaignAddOpenContainter_btnAddMore__3g24i:active {
        opacity: 0.5; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_btnGroup__YM7M_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 20px; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_btnGroup__YM7M_ .CampaignAddOpenContainter_btn__16so4 {
      width: 100px;
      margin: 0px 10px; }
  .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_title__32Prg {
    display: flex;
    flex-direction: row; }
    .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_title__32Prg .CampaignAddOpenContainter_copyAction__30_8O {
      display: flex;
      margin-left: auto;
      align-items: center; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_title__32Prg .CampaignAddOpenContainter_copyAction__30_8O .CampaignAddOpenContainter_copyLink__3IkPp {
        height: 100%;
        display: flex;
        align-items: center; }
        .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_title__32Prg .CampaignAddOpenContainter_copyAction__30_8O .CampaignAddOpenContainter_copyLink__3IkPp input {
          flex: 1 1;
          height: 40px;
          font-size: 16px;
          padding: 0 20px;
          border: 1px solid #dddddd;
          background-color: #fff;
          width: 400px; }
      .CampaignAddOpenContainter_wrapper__POzv4 .CampaignAddOpenContainter_title__32Prg .CampaignAddOpenContainter_copyAction__30_8O button {
        margin-left: 10px; }

.CampaignAddOpenContainter_inputModal__3Mu2n {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px; }
  .CampaignAddOpenContainter_inputModal__3Mu2n input {
    flex: 1 1;
    height: 40px;
    font-size: 16px;
    padding: 0 20px;
    border: 1px solid #dddddd;
    background-color: #f5f5f5; }
    .CampaignAddOpenContainter_inputModal__3Mu2n input:disabled {
      background-color: #eeeeee; }

.CampaignAddOpenContainter_clipListItemDragging__1tCF9 {
  background-color: #eeeeee; }

.CampaignEditContainer_wrapper__9chIJ {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_top__7p3wv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_info__1J7UP {
    border: 1px solid #dddddd;
    padding: 20px 10px;
    margin-bottom: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_info__1J7UP .CampaignEditContainer_title__2dDYi {
      grid-column: 1 / span 2; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_info__1J7UP .CampaignEditContainer_typeIsChat__3vv6y {
      grid-column: 2 / span 1; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_info__1J7UP .CampaignEditContainer_textareaItem__lvOc1 {
      grid-column: 1 / span 2;
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_info__1J7UP .CampaignEditContainer_textareaItem__lvOc1 > span {
        width: 150px;
        min-width: 150px;
        text-align: center;
        margin-top: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_info__1J7UP .CampaignEditContainer_textareaItem__lvOc1 .CampaignEditContainer_textContainer__KPYZ0 {
        flex: 1 1;
        height: 100px;
        padding: 10px 20px;
        border: 1px solid #dddddd;
        overflow-y: auto;
        word-break: break-all; }
  .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_inputItem__2sVA6 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_inputItem__2sVA6 > span {
      width: 150px;
      min-width: 150px;
      text-align: center; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_inputItem__2sVA6 input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_inputItem__2sVA6 input:disabled {
        background-color: #eeeeee; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_inputItem__2sVA6 .CampaignEditContainer_select__3vHSb {
      height: 40px;
      flex: 1 1; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_inputItem__2sVA6 .CampaignEditContainer_select__3vHSb span {
        width: 0px;
        background-color: #ffffff; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_inputItem__2sVA6 .CampaignEditContainer_datepicker__SiS-R {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
  .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_textReview__2bwhy {
    padding-left: 150px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_textReview__2bwhy .CampaignEditContainer_note__1dXyw {
      display: flex;
      margin-right: 10px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_textReview__2bwhy .CampaignEditContainer_title__2dDYi {
      display: inline;
      margin-right: 10px;
      font-weight: bold; }
  .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE span {
      margin-top: 10px;
      width: 150px;
      text-align: center; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE .CampaignEditContainer_logoContainer__3Dq3y {
      flex: 1 1; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE .CampaignEditContainer_logoContainer__3Dq3y .CampaignEditContainer_file__1BryT {
        display: none; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE .CampaignEditContainer_logoContainer__3Dq3y .CampaignEditContainer_upload__3zj9T {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE .CampaignEditContainer_logoContainer__3Dq3y .CampaignEditContainer_upload__3zj9T .CampaignEditContainer_btnUpload__2qqlr {
          flex: 1 1;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          margin-bottom: 10px;
          cursor: pointer; }
          .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE .CampaignEditContainer_logoContainer__3Dq3y .CampaignEditContainer_upload__3zj9T .CampaignEditContainer_btnUpload__2qqlr .CampaignEditContainer_icon__jnt1n {
            margin-right: 5px; }
          .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE .CampaignEditContainer_logoContainer__3Dq3y .CampaignEditContainer_upload__3zj9T .CampaignEditContainer_btnUpload__2qqlr:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_logoItem__3sasE .CampaignEditContainer_logoContainer__3Dq3y span {
        width: 100%;
        margin-top: 10px; }
  .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 20px;
    cursor: initial; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_questionTitle__3DVPZ {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_questionTitle__3DVPZ > h2 {
        margin-right: 10px;
        margin-bottom: 0px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_questionTitle__3DVPZ > span {
        cursor: pointer; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_title__2dDYi {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_title__2dDYi i {
        font-size: 25px;
        color: #b71c1c; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_title__2dDYi i:active {
          opacity: 0.5; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_qtypeContainer__Q-BCy {
      display: flex;
      grid-gap: 20px;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
      flex-wrap: wrap; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_qtypeContainer__Q-BCy .CampaignEditContainer_qtypeRadio__A4QPJ {
        flex: 1 1;
        display: flex;
        align-items: center;
        height: 30px;
        cursor: pointer;
        white-space: nowrap; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_qtypeContainer__Q-BCy .CampaignEditContainer_qtypeRadio__A4QPJ input {
          margin-right: 10px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_inputItem__2sVA6 {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_inputItem__2sVA6 > span {
        width: 150px;
        text-align: left; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_inputItem__2sVA6 input {
        flex: 1 1;
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
        border: 1px solid #dddddd; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_inputItem__2sVA6 input:disabled {
          background-color: #eeeeee; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi span {
        width: 150px;
        margin-top: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_localMedia__3AKUS .CampaignEditContainer_file__1BryT {
        display: none; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_localMedia__3AKUS .CampaignEditContainer_upload__3zj9T {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_localMedia__3AKUS .CampaignEditContainer_upload__3zj9T .CampaignEditContainer_btnUpload__2qqlr {
          width: 300px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          cursor: pointer; }
          .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_localMedia__3AKUS .CampaignEditContainer_upload__3zj9T .CampaignEditContainer_btnUpload__2qqlr:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_localMedia__3AKUS .CampaignEditContainer_mediaView__2HL4t {
        margin-top: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_localMedia__3AKUS .CampaignEditContainer_nomedia__3g4sO {
        width: 100%;
        margin-top: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_or__3YlPr {
        margin: 0px 20px;
        margin-top: 10px;
        font-weight: bold; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_youtubeMedia__2UHr5 .CampaignEditContainer_btnUpload__2qqlr {
        width: 300px;
        height: 40px;
        border-radius: 1px;
        font-size: 16px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        background-color: #03a9f4;
        align-items: center;
        border: 1px solid #03a9f4;
        cursor: pointer; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_youtubeMedia__2UHr5 .CampaignEditContainer_btnUpload__2qqlr:active {
          background-color: #ffffff;
          color: #03a9f4; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_mediaContainer__i6fpi .CampaignEditContainer_youtubeMedia__2UHr5 .CampaignEditContainer_youtubeView__2yVDU {
        margin-top: 10px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_userMediaOptionsContainer__1o332 {
      display: flex;
      align-items: center; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_userMediaRequired__3zjhJ,
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_userMediaShowHide__qN67e {
      height: 40px;
      display: flex;
      align-items: center; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_userMediaRequired__3zjhJ span,
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_userMediaShowHide__qN67e span {
        margin-right: 20px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_userMediaRequired__3zjhJ {
      margin-left: 20px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM span {
        width: 150px;
        margin-top: 10px; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div {
        flex: 1 1; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_answerItem__xE2-k {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_answerItem__xE2-k input {
            flex: 1 1;
            height: 40px;
            font-size: 16px;
            padding: 0 20px;
            border: 1px solid #dddddd;
            margin-bottom: 10px; }
            .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_answerItem__xE2-k input:disabled {
              background-color: #eeeeee; }
          .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_answerItem__xE2-k span {
            width: 30px;
            margin: 0 5px 10px 5px;
            text-align: right; }
            .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_answerItem__xE2-k span i {
              font-size: 24px;
              color: #b71c1c; }
              .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_answerItem__xE2-k span i:active {
                opacity: 0.5; }
        .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_btnAdd__RoBu6 {
          width: 100px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          color: #03a9f4;
          background-color: #ffffff;
          border: 1px solid #03a9f4;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_btnAdd__RoBu6 i {
            margin-right: 5px; }
          .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_answerContainer__1ABDM div .CampaignEditContainer_btnAdd__RoBu6:active {
            background-color: #03a9f4;
            color: #ffffff; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_btnAddMore__3W1vc {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      margin-bottom: 20px;
      cursor: pointer; }
      .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_questionContainer__2YnJl .CampaignEditContainer_btnAddMore__3W1vc:active {
        opacity: 0.5; }
  .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_btnGroup__2Ed03 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 20px; }
    .CampaignEditContainer_wrapper__9chIJ .CampaignEditContainer_btnGroup__2Ed03 .CampaignEditContainer_btn__ArMkT {
      width: 100px;
      margin: 0px 10px; }

.CampaignEditContainer_clipListItemDragging__3xrrQ {
  background-color: #eeeeee; }

.CampaignEditOpenContainer_wrapper__1T1XV {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_top__2D3od {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_tableSpace__6XpWQ {
    padding: 10px 10px;
    border: 1px solid #dddddd;
    margin-bottom: 50px;
    width: 100%; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy {
    display: flex;
    flex-direction: row; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ {
      display: flex;
      margin-left: auto;
      align-items: center;
      min-width: 640px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ .CampaignEditOpenContainer_copyLink__3ZSNj {
        height: 100%;
        display: block;
        align-items: center; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ .CampaignEditOpenContainer_copyLink__3ZSNj input {
          flex: 1 1;
          height: 40px;
          font-size: 16px;
          padding: 0 20px;
          border: 1px solid #dddddd;
          background-color: #eeeeee;
          width: 400px; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ .CampaignEditOpenContainer_copyLink__3ZSNj .CampaignEditOpenContainer_labelshorlink__2skg0 {
          margin-left: 130px; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ .CampaignEditOpenContainer_copyLink__3ZSNj .CampaignEditOpenContainer_labelCurrentLink__1X0H2 {
          margin-top: 10px; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ .CampaignEditOpenContainer_copyLink__3ZSNj .CampaignEditOpenContainer_labelCurrentLink__1X0H2 input {
            background-color: #ffffff;
            width: 510px;
            margin: 10px 0px; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ .CampaignEditOpenContainer_copyLink__3ZSNj .CampaignEditOpenContainer_labelCurrentLink__1X0H2 .CampaignEditOpenContainer_labelCustom__2dtjw {
            margin: 20px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_title__2CzTy .CampaignEditOpenContainer_copyAction__12lHQ button {
        margin-left: 10px; }
  .CampaignEditOpenContainer_wrapper__1T1XV table {
    width: 100%; }
    .CampaignEditOpenContainer_wrapper__1T1XV table.CampaignEditOpenContainer_tableSmall__JkmNf {
      width: 50%; }
    .CampaignEditOpenContainer_wrapper__1T1XV table h2 {
      font-weight: 600; }
    .CampaignEditOpenContainer_wrapper__1T1XV table td {
      flex: 1 1;
      margin-left: 10px;
      margin-right: 10px;
      width: 50%; }
    .CampaignEditOpenContainer_wrapper__1T1XV table .CampaignEditOpenContainer_textareaItem__3dsqW {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV table .CampaignEditOpenContainer_textareaItem__3dsqW > span {
        width: 150px;
        min-width: 150px;
        text-align: center;
        margin-top: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV table .CampaignEditOpenContainer_textareaItem__3dsqW .CampaignEditOpenContainer_textContainer__3LQYq {
        flex: 1 1;
        height: 100px;
        padding: 10px 20px;
        border: 1px solid #dddddd;
        overflow-y: auto;
        word-break: break-all; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_height40p__1-1IQ {
    height: 40px; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_inputItem__2FPem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_inputItem__2FPem > span {
      width: 150px;
      min-width: 150px;
      text-align: center; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_inputItem__2FPem input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_inputItem__2FPem input:disabled {
        background-color: #eeeeee; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_inputItem__2FPem .CampaignEditOpenContainer_select__2AlQR {
      height: 40px;
      flex: 1 1; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_inputItem__2FPem .CampaignEditOpenContainer_select__2AlQR span {
        width: 0px;
        background-color: #ffffff; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_inputItem__2FPem .CampaignEditOpenContainer_datepicker__fjf3x {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_requiredField__2GuBp {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 150px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_requiredField__2GuBp .CampaignEditOpenContainer_name__1S6CO span,
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_requiredField__2GuBp .CampaignEditOpenContainer_email__1vP8a span {
      margin-left: 10px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_requiredField__2GuBp .CampaignEditOpenContainer_name__1S6CO {
      margin-right: 10px; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_textReview__2PmP8 {
    padding-left: 150px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_textReview__2PmP8 .CampaignEditOpenContainer_note__2WQI9 {
      display: flex;
      margin-right: 10px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_textReview__2PmP8 .CampaignEditOpenContainer_title__2CzTy {
      display: inline;
      margin-right: 10px;
      font-weight: bold; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 span {
      margin-top: 10px;
      width: 150px;
      text-align: center; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 .CampaignEditOpenContainer_logoContainer__3IsnS {
      flex: 1 1; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 .CampaignEditOpenContainer_logoContainer__3IsnS .CampaignEditOpenContainer_file__3yfL2 {
        display: none; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 .CampaignEditOpenContainer_logoContainer__3IsnS .CampaignEditOpenContainer_upload__27MLo {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 .CampaignEditOpenContainer_logoContainer__3IsnS .CampaignEditOpenContainer_upload__27MLo .CampaignEditOpenContainer_btnUpload__1j4WJ {
          flex: 1 1;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          margin-bottom: 10px;
          cursor: pointer; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 .CampaignEditOpenContainer_logoContainer__3IsnS .CampaignEditOpenContainer_upload__27MLo .CampaignEditOpenContainer_btnUpload__1j4WJ .CampaignEditOpenContainer_icon__204gF {
            margin-right: 5px; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 .CampaignEditOpenContainer_logoContainer__3IsnS .CampaignEditOpenContainer_upload__27MLo .CampaignEditOpenContainer_btnUpload__1j4WJ:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_logoItem__3AKD7 .CampaignEditOpenContainer_logoContainer__3IsnS span {
        width: 100%;
        margin-top: 10px; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 20px;
    cursor: initial; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_questionTitle__ut3rt {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_questionTitle__ut3rt > h2 {
        margin-right: 10px;
        margin-bottom: 0px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_questionTitle__ut3rt > span {
        cursor: pointer; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_title__2CzTy {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_title__2CzTy i {
        font-size: 25px;
        color: #b71c1c; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_title__2CzTy i:active {
          opacity: 0.5; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_qtypeContainer__1QN0z {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_qtypeContainer__1QN0z .CampaignEditOpenContainer_qtypeRadio__30fxh {
        flex: 1 1;
        display: flex;
        align-items: center;
        height: 30px;
        cursor: pointer; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_qtypeContainer__1QN0z .CampaignEditOpenContainer_qtypeRadio__30fxh input {
          margin-right: 10px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_inputItem__2FPem {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_inputItem__2FPem > span {
        width: 150px;
        text-align: left; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_inputItem__2FPem input {
        flex: 1 1;
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
        border: 1px solid #dddddd; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_inputItem__2FPem input:disabled {
          background-color: #eeeeee; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi span {
        width: 150px;
        margin-top: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_localMedia__2hEzN .CampaignEditOpenContainer_file__3yfL2 {
        display: none; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_localMedia__2hEzN .CampaignEditOpenContainer_upload__27MLo {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_localMedia__2hEzN .CampaignEditOpenContainer_upload__27MLo .CampaignEditOpenContainer_btnUpload__1j4WJ {
          width: 300px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          cursor: pointer; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_localMedia__2hEzN .CampaignEditOpenContainer_upload__27MLo .CampaignEditOpenContainer_btnUpload__1j4WJ:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_localMedia__2hEzN .CampaignEditOpenContainer_mediaView__30H5l {
        margin-top: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_localMedia__2hEzN .CampaignEditOpenContainer_nomedia__2ZuWn {
        width: 100%;
        margin-top: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_or__1t9wj {
        margin: 0px 20px;
        margin-top: 10px;
        font-weight: bold; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_youtubeMedia__3jf0n .CampaignEditOpenContainer_btnUpload__1j4WJ {
        width: 300px;
        height: 40px;
        border-radius: 1px;
        font-size: 16px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        background-color: #03a9f4;
        align-items: center;
        border: 1px solid #03a9f4;
        cursor: pointer; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_youtubeMedia__3jf0n .CampaignEditOpenContainer_btnUpload__1j4WJ:active {
          background-color: #ffffff;
          color: #03a9f4; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_mediaContainer__9Y8qi .CampaignEditOpenContainer_youtubeMedia__3jf0n .CampaignEditOpenContainer_youtubeView__p4IKb {
        margin-top: 10px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_userMediaOptionsContainer__2G5Qf {
      display: flex;
      align-items: center; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_userMediaRequired__2XvFk,
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_userMediaShowHide__zWg7u {
      height: 40px;
      display: flex;
      align-items: center; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_userMediaRequired__2XvFk span,
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_userMediaShowHide__zWg7u span {
        margin-right: 20px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_userMediaRequired__2XvFk {
      margin-left: 20px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk span {
        width: 150px;
        margin-top: 10px; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div {
        flex: 1 1; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_answerItem__15wQa {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_answerItem__15wQa input {
            flex: 1 1;
            height: 40px;
            font-size: 16px;
            padding: 0 20px;
            border: 1px solid #dddddd;
            margin-bottom: 10px; }
            .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_answerItem__15wQa input:disabled {
              background-color: #eeeeee; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_answerItem__15wQa span {
            width: 30px;
            margin: 0 5px 10px 5px;
            text-align: right; }
            .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_answerItem__15wQa span i {
              font-size: 24px;
              color: #b71c1c; }
              .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_answerItem__15wQa span i:active {
                opacity: 0.5; }
        .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_btnAdd__P_Dqa {
          width: 100px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          color: #03a9f4;
          background-color: #ffffff;
          border: 1px solid #03a9f4;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_btnAdd__P_Dqa i {
            margin-right: 5px; }
          .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_answerContainer__2QjJk div .CampaignEditOpenContainer_btnAdd__P_Dqa:active {
            background-color: #03a9f4;
            color: #ffffff; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_btnAddMore__29Rdo {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      margin-bottom: 20px;
      cursor: pointer; }
      .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_questionContainer__19G7l .CampaignEditOpenContainer_btnAddMore__29Rdo:active {
        opacity: 0.5; }
  .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_btnGroup__1GkhU {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 20px; }
    .CampaignEditOpenContainer_wrapper__1T1XV .CampaignEditOpenContainer_btnGroup__1GkhU .CampaignEditOpenContainer_btn__2CX9a {
      width: 100px;
      margin: 0px 10px; }

.CampaignEditOpenContainer_inputModal__m2vWb {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px; }
  .CampaignEditOpenContainer_inputModal__m2vWb input {
    flex: 1 1;
    height: 40px;
    font-size: 16px;
    padding: 0 20px;
    border: 1px solid #dddddd;
    background-color: #f5f5f5; }
    .CampaignEditOpenContainer_inputModal__m2vWb input:disabled {
      background-color: #eeeeee; }

.CampaignEditOpenContainer_clipListItemDragging__3_nen {
  background-color: #eeeeee; }

.SessionListContainer_wrapper__116sH {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
    .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA {
      flex: 1 1; }
      .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchbar__37R7o {
        height: 40px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #03a9f4;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        margin-right: 20px; }
        .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchbar__37R7o:active {
          background-color: #ffffff; }
        .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchbar__37R7o .SessionListContainer_iconSearch__1jq6q {
          color: #03a9f4;
          font-size: 18px;
          margin-right: 5px; }
        .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchbar__37R7o input {
          width: 100%;
          height: 40px;
          font-size: 16px;
          border-style: none; }
          .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchbar__37R7o input:focus {
            outline: none; }
      .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchfilters__2jgqN {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchfilters__2jgqN .SessionListContainer_filter__n-Xmr {
          flex: 1 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          cursor: pointer; }
          .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_searchContainer__3mEVA .SessionListContainer_searchfilters__2jgqN .SessionListContainer_filter__n-Xmr input {
            margin-right: 5px; }
    .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_btnAdd__SGQ0S {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer; }
      .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_btnAdd__SGQ0S .SessionListContainer_icon__1RT5Q {
        margin-right: 5px; }
      .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_btnAdd__SGQ0S:focus {
        outline: none; }
      .SessionListContainer_wrapper__116sH .SessionListContainer_top__3bD7F .SessionListContainer_btnAdd__SGQ0S:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .SessionListContainer_wrapper__116sH table {
    width: 100%;
    border-collapse: collapse; }
  .SessionListContainer_wrapper__116sH .SessionListContainer_header__JKOXV {
    background: #03a9f4; }
  .SessionListContainer_wrapper__116sH th {
    height: 40px;
    padding: 10px 10px;
    color: #ffffff; }
  .SessionListContainer_wrapper__116sH td {
    padding: 10px 10px; }
  .SessionListContainer_wrapper__116sH table,
  .SessionListContainer_wrapper__116sH th,
  .SessionListContainer_wrapper__116sH td {
    border: 1px solid #dddddd;
    text-align: center; }
  .SessionListContainer_wrapper__116sH .SessionListContainer_name__3FNbh {
    text-decoration: underline;
    color: #03a9f4;
    cursor: pointer; }

.SessionAddContainer_wrapper__20wvR {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .SessionAddContainer_wrapper__20wvR .SessionAddContainer_top__ktPJu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .SessionAddContainer_wrapper__20wvR table {
    border: 1px solid #dddddd;
    padding: 20px 10px;
    margin-bottom: 50px;
    width: 100%; }
    .SessionAddContainer_wrapper__20wvR table h2 {
      font-weight: 600; }
    .SessionAddContainer_wrapper__20wvR table tr {
      display: flex;
      justify-content: space-between; }
    .SessionAddContainer_wrapper__20wvR table td {
      flex: 1 1;
      margin-left: 10px;
      margin-right: 10px; }
    .SessionAddContainer_wrapper__20wvR table .SessionAddContainer_textareaItem__2Zgte {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 10px; }
      .SessionAddContainer_wrapper__20wvR table .SessionAddContainer_textareaItem__2Zgte span {
        width: 150px;
        text-align: center;
        margin-top: 10px; }
      .SessionAddContainer_wrapper__20wvR table .SessionAddContainer_textareaItem__2Zgte .SessionAddContainer_textContainer__1snyO {
        flex: 1 1;
        height: 100px;
        padding: 10px 20px;
        border: 1px solid #dddddd;
        overflow-y: auto;
        word-break: break-all; }
  .SessionAddContainer_wrapper__20wvR .SessionAddContainer_inputItem__3vHdR {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_inputItem__3vHdR span {
      width: 150px;
      text-align: center; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_inputItem__3vHdR input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_inputItem__3vHdR input:disabled {
        background-color: #eeeeee; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_inputItem__3vHdR .SessionAddContainer_select__2DQqn {
      height: 40px;
      flex: 1 1; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_inputItem__3vHdR .SessionAddContainer_select__2DQqn span {
        width: 0px;
        background-color: #ffffff; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_inputItem__3vHdR .SessionAddContainer_datepicker__1fc0Y {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
  .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 span {
      margin-top: 10px;
      width: 150px;
      text-align: center; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp {
      flex: 1 1; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_file__2BTMG {
        display: none; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_upload__32_zE {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_upload__32_zE .SessionAddContainer_btnUpload__1nZdR {
          flex: 1 1;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          margin-bottom: 10px;
          cursor: pointer; }
          .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_upload__32_zE .SessionAddContainer_btnUpload__1nZdR .SessionAddContainer_icon__1fVJG {
            margin-right: 5px; }
          .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_upload__32_zE .SessionAddContainer_btnUpload__1nZdR:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_logo__1HkGT {
        width: 100%;
        position: relative; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_logo__1HkGT img {
          width: 100%;
          margin-top: 10px; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_logo__1HkGT div {
          position: absolute;
          top: 10px;
          right: 0px;
          margin-bottom: 15px;
          margin-left: 10px; }
          .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_logo__1HkGT div i {
            font-size: 30px;
            color: #b71c1c; }
            .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp .SessionAddContainer_logo__1HkGT div i:active {
              opacity: 0.5; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_logoItem__2BkQ7 .SessionAddContainer_logoContainer__jQuAp span {
        width: 100%;
        margin-top: 10px; }
  .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 20px; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_title__BIH_S {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_title__BIH_S i {
        font-size: 32px;
        color: #b71c1c; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_title__BIH_S i:active {
          opacity: 0.5; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_inputItem__3vHdR {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_inputItem__3vHdR span {
        width: 150px;
        text-align: left; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_inputItem__3vHdR input {
        flex: 1 1;
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
        border: 1px solid #dddddd; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_inputItem__3vHdR input:disabled {
          background-color: #eeeeee; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm span {
        width: 150px;
        margin-top: 10px; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_file__2BTMG {
        display: none; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_upload__32_zE {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_upload__32_zE .SessionAddContainer_btnUpload__1nZdR {
          width: 300px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          cursor: pointer; }
          .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_upload__32_zE .SessionAddContainer_btnUpload__1nZdR:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_mediaView__BRwT2 {
        position: relative; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_mediaView__BRwT2 .SessionAddContainer_media__1J1L_ {
          width: 300px;
          margin-top: 10px; }
        .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_mediaView__BRwT2 div {
          position: absolute;
          top: 10px;
          right: 0px;
          width: 30px; }
          .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_mediaView__BRwT2 div i {
            font-size: 30px;
            color: #b71c1c; }
            .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_mediaView__BRwT2 div i:active {
              opacity: 0.5; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_mediaContainer__3BJrm .SessionAddContainer_media__1J1L_ .SessionAddContainer_nomedia__bXwBx {
        width: 100%;
        margin-top: 10px; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_btnAddMore__3X5hl {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      cursor: pointer; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_sessionContainer__3WI6s .SessionAddContainer_btnAddMore__3X5hl:active {
        opacity: 0.5; }
  .SessionAddContainer_wrapper__20wvR .SessionAddContainer_btnGroup__3VkYl {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_btnGroup__3VkYl .SessionAddContainer_btnSave__1mZ9f {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer;
      margin-right: 50px; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_btnGroup__3VkYl .SessionAddContainer_btnSave__1mZ9f:active {
        background-color: #03a9f4;
        color: #ffffff; }
    .SessionAddContainer_wrapper__20wvR .SessionAddContainer_btnGroup__3VkYl .SessionAddContainer_btnCancel__2Razf {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #b71c1c;
      align-items: center;
      border: 1px solid #b71c1c;
      cursor: pointer; }
      .SessionAddContainer_wrapper__20wvR .SessionAddContainer_btnGroup__3VkYl .SessionAddContainer_btnCancel__2Razf:active {
        background-color: #b71c1c;
        color: #ffffff; }

.SessionEditContainer_wrapper__1osoI {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .SessionEditContainer_wrapper__1osoI .SessionEditContainer_top__2aznE {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .SessionEditContainer_wrapper__1osoI table {
    border: 1px solid #dddddd;
    padding: 20px 10px;
    margin-bottom: 50px;
    width: 100%; }
    .SessionEditContainer_wrapper__1osoI table h2 {
      font-weight: 600; }
    .SessionEditContainer_wrapper__1osoI table tr {
      display: flex;
      justify-content: space-between; }
    .SessionEditContainer_wrapper__1osoI table td {
      flex: 1 1;
      margin-left: 10px;
      margin-right: 10px; }
    .SessionEditContainer_wrapper__1osoI table .SessionEditContainer_textareaItem__2CQS- {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 10px; }
      .SessionEditContainer_wrapper__1osoI table .SessionEditContainer_textareaItem__2CQS- span {
        width: 150px;
        text-align: center;
        margin-top: 10px; }
      .SessionEditContainer_wrapper__1osoI table .SessionEditContainer_textareaItem__2CQS- .SessionEditContainer_textContainer__1fIjC {
        flex: 1 1;
        height: 100px;
        padding: 10px 20px;
        border: 1px solid #dddddd;
        overflow-y: auto;
        word-break: break-all; }
  .SessionEditContainer_wrapper__1osoI .SessionEditContainer_inputItem__g9GxA {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_inputItem__g9GxA span {
      width: 150px;
      text-align: center; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_inputItem__g9GxA input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_inputItem__g9GxA input:disabled {
        background-color: #eeeeee; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_inputItem__g9GxA .SessionEditContainer_select__2B6Mt {
      height: 40px;
      flex: 1 1; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_inputItem__g9GxA .SessionEditContainer_select__2B6Mt span {
        width: 0px;
        background-color: #ffffff; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_inputItem__g9GxA .SessionEditContainer_datepicker__AKlLp {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
  .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q span {
      margin-top: 10px;
      width: 150px;
      text-align: center; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 {
      flex: 1 1; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_file__VByP1 {
        display: none; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_upload__39fyZ {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_upload__39fyZ .SessionEditContainer_btnUpload__2Jdn9 {
          flex: 1 1;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          margin-bottom: 10px;
          cursor: pointer; }
          .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_upload__39fyZ .SessionEditContainer_btnUpload__2Jdn9 .SessionEditContainer_icon__3YBkh {
            margin-right: 5px; }
          .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_upload__39fyZ .SessionEditContainer_btnUpload__2Jdn9:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_logo__NZBFG {
        width: 100%;
        position: relative; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_logo__NZBFG img {
          width: 100%;
          margin-top: 10px; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_logo__NZBFG div {
          position: absolute;
          top: 10px;
          right: 0px;
          margin-bottom: 15px;
          margin-left: 10px; }
          .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_logo__NZBFG div i {
            font-size: 30px;
            color: #b71c1c; }
            .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 .SessionEditContainer_logo__NZBFG div i:active {
              opacity: 0.5; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_logoItem__1w22Q .SessionEditContainer_logoContainer__2L2s7 span {
        width: 100%;
        margin-top: 10px; }
  .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 20px; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_title__3O_oI {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_title__3O_oI i {
        font-size: 32px;
        color: #b71c1c; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_title__3O_oI i:active {
          opacity: 0.5; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_inputItem__g9GxA {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_inputItem__g9GxA span {
        width: 150px;
        text-align: left; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_inputItem__g9GxA input {
        flex: 1 1;
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
        border: 1px solid #dddddd; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_inputItem__g9GxA input:disabled {
          background-color: #eeeeee; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS span {
        width: 150px;
        margin-top: 10px; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_file__VByP1 {
        display: none; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_upload__39fyZ {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_upload__39fyZ .SessionEditContainer_btnUpload__2Jdn9 {
          width: 300px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: #ffffff;
          background-color: #03a9f4;
          align-items: center;
          border: 1px solid #03a9f4;
          cursor: pointer; }
          .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_upload__39fyZ .SessionEditContainer_btnUpload__2Jdn9:active {
            background-color: #ffffff;
            color: #03a9f4; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_mediaView__EzUaa {
        position: relative; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_mediaView__EzUaa .SessionEditContainer_media__5HzBg {
          width: 300px;
          margin-top: 10px; }
        .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_mediaView__EzUaa div {
          position: absolute;
          top: 10px;
          right: 0px;
          width: 30px; }
          .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_mediaView__EzUaa div i {
            font-size: 30px;
            color: #b71c1c; }
            .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_mediaView__EzUaa div i:active {
              opacity: 0.5; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_mediaContainer__3L3HS .SessionEditContainer_media__5HzBg .SessionEditContainer_nomedia__ecNVB {
        width: 100%;
        margin-top: 10px; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_btnAddMore__1So_G {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: #03a9f4;
      margin-bottom: 20px;
      cursor: pointer; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_sessionContainer__wwr7G .SessionEditContainer_btnAddMore__1So_G:active {
        opacity: 0.5; }
  .SessionEditContainer_wrapper__1osoI .SessionEditContainer_btnGroup__DWTIk {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_btnGroup__DWTIk .SessionEditContainer_btnSave__1scXm {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer;
      margin-right: 50px; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_btnGroup__DWTIk .SessionEditContainer_btnSave__1scXm:active {
        background-color: #03a9f4;
        color: #ffffff; }
    .SessionEditContainer_wrapper__1osoI .SessionEditContainer_btnGroup__DWTIk .SessionEditContainer_btnCancel__1DTJD {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #b71c1c;
      align-items: center;
      border: 1px solid #b71c1c;
      cursor: pointer; }
      .SessionEditContainer_wrapper__1osoI .SessionEditContainer_btnGroup__DWTIk .SessionEditContainer_btnCancel__1DTJD:active {
        background-color: #b71c1c;
        color: #ffffff; }

.ReportContainer_wrapper__3k3yz {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .ReportContainer_wrapper__3k3yz .ReportContainer_top__1hpQs {
    display: flex;
    margin-bottom: 1px; }
    .ReportContainer_wrapper__3k3yz .ReportContainer_top__1hpQs .ReportContainer_searchbar__2PCmv {
      flex: 1 1;
      height: 40px;
      border-radius: 20px;
      background-color: #ffffff;
      border: 1px solid #03a9f4;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      margin-bottom: 10px; }
      .ReportContainer_wrapper__3k3yz .ReportContainer_top__1hpQs .ReportContainer_searchbar__2PCmv:active {
        background-color: #ffffff; }
      .ReportContainer_wrapper__3k3yz .ReportContainer_top__1hpQs .ReportContainer_searchbar__2PCmv .ReportContainer_iconSearch__2rwWX {
        color: #03a9f4;
        font-size: 18px;
        margin-right: 5px; }
      .ReportContainer_wrapper__3k3yz .ReportContainer_top__1hpQs .ReportContainer_searchbar__2PCmv input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-style: none; }
        .ReportContainer_wrapper__3k3yz .ReportContainer_top__1hpQs .ReportContainer_searchbar__2PCmv input:focus {
          outline: none; }
  .ReportContainer_wrapper__3k3yz table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px; }
  .ReportContainer_wrapper__3k3yz .ReportContainer_header__3uvVL {
    background: #03a9f4; }
  .ReportContainer_wrapper__3k3yz th {
    height: 40px;
    padding: 10px 10px;
    color: #ffffff; }
  .ReportContainer_wrapper__3k3yz td {
    padding: 10px 10px; }
  .ReportContainer_wrapper__3k3yz table,
  .ReportContainer_wrapper__3k3yz th,
  .ReportContainer_wrapper__3k3yz td {
    border: 1px solid #dddddd;
    text-align: center; }
  .ReportContainer_wrapper__3k3yz .ReportContainer_campaign__2eqlT {
    text-decoration: underline;
    color: #03a9f4;
    cursor: pointer; }
  .ReportContainer_wrapper__3k3yz .ReportContainer_selector__1u2cD {
    width: 500px;
    display: flex;
    flex-direction: row;
    padding: 4px;
    margin-bottom: 15px; }
    .ReportContainer_wrapper__3k3yz .ReportContainer_selector__1u2cD span {
      flex: 1 1;
      padding: 6px; }
    .ReportContainer_wrapper__3k3yz .ReportContainer_selector__1u2cD .ReportContainer_select__3420U {
      flex: 3 1; }
  .ReportContainer_wrapper__3k3yz h3 {
    margin: 8px; }
  .ReportContainer_wrapper__3k3yz .ReportContainer_select__3420U span {
    background-color: #ffffff; }

.MediaItem_videoDownload__1q4K2 {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .MediaItem_videoDownload__1q4K2 > button {
    margin-top: 10px;
    width: 100%;
    max-width: 150px; }

.SummaryClips_videoWrap__M4t7i {
  display: flex;
  flex-direction: column;
  align-items: center; }

.SummaryClips_table__2eWtb .SummaryClips_clipTitle__1bw8b {
  font-size: 18px;
  color: #555555;
  margin-bottom: 0px; }

.SummaryClips_table__2eWtb thead > tr > th {
  font-size: 18px;
  color: #555555;
  font-weight: bold; }

.SummaryAnswer_userSummaryAnswer__2LoBd {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.SummaryMedia_userSummaryMedia__3_AhT {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px; }
  .SummaryMedia_userSummaryMedia__3_AhT .SummaryMedia_userMedia__2CtSk {
    height: 150px;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: auto;
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin-top: 10px; }
  .SummaryMedia_userSummaryMedia__3_AhT .SummaryMedia_mediaWraper__3ZEDZ {
    display: flex;
    flex-direction: column; }
    .SummaryMedia_userSummaryMedia__3_AhT .SummaryMedia_mediaWraper__3ZEDZ .SummaryMedia_dateTime__Rt8h0 {
      align-self: flex-start;
      margin-bottom: 4px;
      font-size: 12px;
      color: gray; }

.SummaryTranscriptions_wrapper__22Fp6 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .SummaryTranscriptions_wrapper__22Fp6 .SummaryTranscriptions_createClipBtn__YG8HO {
    width: 150px;
    margin-left: 16px;
    margin-bottom: 16px; }
  .SummaryTranscriptions_wrapper__22Fp6 .SummaryTranscriptions_clearButton__20ySV {
    width: 100px; }
  .SummaryTranscriptions_wrapper__22Fp6 .SummaryTranscriptions_userSummaryTranscription__2RBH8 {
    padding: 16px 16px 0px 16px; }

.SummaryTranscriptions_noTranscriptWrapper__1Ct7l {
  padding: 16px;
  margin-bottom: 0px;
  color: #b71c1c; }

.Transcriptions_noSelect__2spck {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: text; }
  .Transcriptions_noSelect__2spck .Transcriptions_textDanger__3wiPU {
    color: #b71c1c; }

.Word_selected__YRwbJ {
  background-color: #3297fd;
  color: white; }

.Word_hovered__2b3jr {
  background-color: #dddddd;
  opacity: 0.7;
  color: black; }

.SummaryTableItem_userSummaryTable__Rn-2L {
  border: 1px solid #f0f0f0;
  margin-bottom: 16px; }
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_userName__1gpRX,
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_media__1t-Vl,
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_textTranscript__FKt8E {
    padding: 16px;
    margin-bottom: 0px;
    border-bottom: 1px solid #f0f0f0; }
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_textAnswer__3n4qQ,
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_answer__1YYS3 {
    padding: 16px;
    margin-bottom: 0px;
    border-left: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0; }
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_userName__1gpRX,
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_textAnswer__3n4qQ,
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_textTranscript__FKt8E {
    background-color: #fafafa; }
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_headerTitle__3N0-f {
    font-size: 18px;
    font-weight: bold;
    color: #555555;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_flexCol__2u5Kd {
    display: flex;
    flex-direction: column; }
    .SummaryTableItem_userSummaryTable__Rn-2L .SummaryTableItem_flexCol__2u5Kd .SummaryTableItem_grow__2HigG {
      flex-grow: 1; }

.SummaryTable_modal__rY-7B .SummaryTable_modalTitle__1bt-e {
  font-weight: bold;
  font-size: 16px;
  margin-left: 8px; }

.SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 400px;
  overflow-y: auto; }
  .SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV .SummaryTable_modalContent__2286R {
    display: flex;
    margin: 24px 12px 0 12px; }
    .SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV .SummaryTable_modalContent__2286R .SummaryTable_avatar__10G0P {
      width: 36px;
      margin-right: 16px;
      margin-top: 2px; }
    .SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV .SummaryTable_modalContent__2286R .SummaryTable_username__2zCts {
      font-weight: bold;
      font-size: 12px; }
    .SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV .SummaryTable_modalContent__2286R .SummaryTable_dateTime__251CS {
      font-size: 12px;
      color: gray; }
    .SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV .SummaryTable_modalContent__2286R .SummaryTable_messageGroup__cXNdS {
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
      gap: 16px; }
    .SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV .SummaryTable_modalContent__2286R .SummaryTable_wrapperMessage__3KN6L {
      display: flex;
      grid-gap: 32px;
      gap: 32px; }
      .SummaryTable_modal__rY-7B .SummaryTable_modalWrapperContent__3uuiV .SummaryTable_modalContent__2286R .SummaryTable_wrapperMessage__3KN6L .SummaryTable_wrapperVideo__hwsUt {
        display: flex;
        flex-direction: column;
        grid-gap: 8px;
        gap: 8px; }

.SummaryTable_modal__rY-7B .SummaryTable_modalFooter__1IUzX {
  margin: 12px 20px; }
  .SummaryTable_modal__rY-7B .SummaryTable_modalFooter__1IUzX .SummaryTable_wrapperCheckbox__1PMby {
    display: flex;
    margin: 12px 0; }
    .SummaryTable_modal__rY-7B .SummaryTable_modalFooter__1IUzX .SummaryTable_wrapperCheckbox__1PMby .SummaryTable_requireVideoCheckbox__3prvN {
      margin-right: 16px; }
  .SummaryTable_modal__rY-7B .SummaryTable_modalFooter__1IUzX .SummaryTable_markRejected__2HaHM {
    color: #fff;
    border-color: #ff4d4f;
    background: #ff4d4f; }

.SummaryReportOneChoice_wrapper__2kosp {
  padding: 20px;
  margin-bottom: 20px;
  z-index: 1; }
  .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_columns__3CD3N {
    flex: 1 1;
    padding-left: 20px;
    min-width: 50%; }
    .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_columns__3CD3N .SummaryReportOneChoice_typeReport__1ezX5 {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600; }
    .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_columns__3CD3N .SummaryReportOneChoice_questionTitle__2Nmqy {
      font-size: 16px;
      font-weight: 400; }
    .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_columns__3CD3N .SummaryReportOneChoice_subTypeReport__2kvGA {
      display: flex;
      justify-content: center;
      margin-bottom: 10px; }

.SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_columns__3CD3N .SummaryReportOneChoice_tableSummary__r5n8c {
  width: 50%;
  margin: auto; }

.SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_answerContainer__1V8QW {
  margin-top: 20px; }
  .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_answerContainer__1V8QW .SummaryReportOneChoice_answerItem__1uLu1 {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_answerContainer__1V8QW .SummaryReportOneChoice_answerItem__1uLu1 span {
      margin-left: 5px; }

.SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_userMediaContainer__2pWud {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap; }
  .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_userMediaContainer__2pWud .SummaryReportOneChoice_videoDownload__3MQCm {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_userMediaContainer__2pWud .SummaryReportOneChoice_videoDownload__3MQCm > button {
      margin-top: 10px;
      width: 100%;
      max-width: 150px; }
  .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_userMediaContainer__2pWud .SummaryReportOneChoice_userMedia__3Du5n {
    height: 150px;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: auto;
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin-top: 10px; }

.SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_feedbackContainer__1vzrG {
  margin-top: 20px; }
  .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_feedbackContainer__1vzrG h4 {
    margin-bottom: 0px;
    margin-right: 10px; }
  .SummaryReportOneChoice_wrapper__2kosp .SummaryReportOneChoice_feedbackContainer__1vzrG .SummaryReportOneChoice_feedback__lKds8 {
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #dddddd;
    margin-top: 10px; }

.Media_wrapper__2cPPB .Media_media__vE43p {
  width: 400px; }

.SummaryReportMultipleChoice_wrapper__sIkCe {
  padding: 20px;
  margin-bottom: 20px;
  z-index: 1; }
  .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_columns__1Ok7R {
    flex: 1 1;
    padding-left: 20px;
    min-width: 50%; }
    .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_columns__1Ok7R .SummaryReportMultipleChoice_typeReport__1XlLg {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600; }
    .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_columns__1Ok7R .SummaryReportMultipleChoice_questionTitle__3GrGc {
      font-size: 16px;
      font-weight: 400; }
    .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_columns__1Ok7R .SummaryReportMultipleChoice_subTypeReport__1W_Ag {
      display: flex;
      justify-content: center;
      margin-bottom: 10px; }

.SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_columns__1Ok7R .SummaryReportMultipleChoice_tableSummary__2GqFv {
  width: 50%;
  margin: auto; }

.SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_answerContainer___x1JT {
  margin-top: 20px; }
  .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_answerContainer___x1JT .SummaryReportMultipleChoice_answerItem__3YF8u {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_answerContainer___x1JT .SummaryReportMultipleChoice_answerItem__3YF8u span {
      margin-left: 5px; }

.SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_userMediaContainer__U9dv5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap; }
  .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_userMediaContainer__U9dv5 .SummaryReportMultipleChoice_videoDownload__3p7Hx {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_userMediaContainer__U9dv5 .SummaryReportMultipleChoice_videoDownload__3p7Hx > button {
      margin-top: 10px;
      width: 100%;
      max-width: 150px; }
  .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_userMediaContainer__U9dv5 .SummaryReportMultipleChoice_userMedia__3VuON {
    height: 150px;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: auto;
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin-top: 10px; }

.SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_feedbackContainer__1GOHq {
  margin-top: 20px; }
  .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_feedbackContainer__1GOHq h4 {
    margin-bottom: 0px;
    margin-right: 10px; }
  .SummaryReportMultipleChoice_wrapper__sIkCe .SummaryReportMultipleChoice_feedbackContainer__1GOHq .SummaryReportMultipleChoice_feedback__1zHh4 {
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #dddddd;
    margin-top: 10px; }

.SummaryReportRankingChoice_wrapper__2_Jxb {
  padding: 20px;
  margin-bottom: 20px;
  z-index: 1; }
  .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_columns__3kLGM {
    flex: 1 1;
    padding-left: 20px;
    min-width: 50%; }
    .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_columns__3kLGM .SummaryReportRankingChoice_typeReport__1LQcO {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600; }
    .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_columns__3kLGM .SummaryReportRankingChoice_questionTitle__2WMGC {
      font-size: 16px;
      font-weight: 400; }
    .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_columns__3kLGM .SummaryReportRankingChoice_subTypeReport__emMPG {
      display: flex;
      justify-content: center;
      margin-bottom: 10px; }

.SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_columns__3kLGM .SummaryReportRankingChoice_tableSummary__3D6yV {
  width: 50%;
  margin: auto; }

.SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_answerContainer__31fQ8 {
  margin-top: 20px; }
  .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_answerContainer__31fQ8 .SummaryReportRankingChoice_answerItem__3JIMp {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_answerContainer__31fQ8 .SummaryReportRankingChoice_answerItem__3JIMp span {
      margin-left: 5px; }

.SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_userMediaContainer__2bvwx {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap; }
  .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_userMediaContainer__2bvwx .SummaryReportRankingChoice_videoDownload__1ohkP {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_userMediaContainer__2bvwx .SummaryReportRankingChoice_videoDownload__1ohkP > button {
      margin-top: 10px;
      width: 100%;
      max-width: 150px; }
  .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_userMediaContainer__2bvwx .SummaryReportRankingChoice_userMedia__1YFXr {
    height: 150px;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: auto;
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin-top: 10px; }

.SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_feedbackContainer__1_0g1 {
  margin-top: 20px; }
  .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_feedbackContainer__1_0g1 h4 {
    margin-bottom: 0px;
    margin-right: 10px; }
  .SummaryReportRankingChoice_wrapper__2_Jxb .SummaryReportRankingChoice_feedbackContainer__1_0g1 .SummaryReportRankingChoice_feedback__1b-2A {
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #dddddd;
    margin-top: 10px; }

.Clips_wrapper__3AU1a {
  width: 100%; }
  .Clips_wrapper__3AU1a .Clips_clipList__2YZxP {
    padding: 15px;
    background-color: #dddddd; }
  .Clips_wrapper__3AU1a .Clips_clipListItem__3d9WQ {
    padding: 15px;
    background-color: #ffffff;
    cursor: pointer; }
    .Clips_wrapper__3AU1a .Clips_clipListItem__3d9WQ:not(:last-child) {
      margin-bottom: 15px; }
  .Clips_wrapper__3AU1a .Clips_clipListItemDragging__2F-hE {
    background-color: #eeeeee; }
  .Clips_wrapper__3AU1a .Clips_btnGroup___PmOC {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .Clips_wrapper__3AU1a .Clips_btnGroup___PmOC .Clips_save__3k38w {
      width: 100px;
      height: 40px;
      font-weight: 600; }

.ClipResult_wrapper__3jgRw {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .ClipResult_wrapper__3jgRw .ClipResult_clipResultItem__oNhZt {
    text-align: center;
    width: calc(33% - 20px);
    margin: 10px; }
    .ClipResult_wrapper__3jgRw .ClipResult_clipResultItem__oNhZt .ClipResult_clipVideo__X-aoS {
      width: 100%;
      height: 300px;
      background-color: lightgrey; }
    .ClipResult_wrapper__3jgRw .ClipResult_clipResultItem__oNhZt .ClipResult_action__1KeNl {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .ClipResult_wrapper__3jgRw .ClipResult_clipResultItem__oNhZt .ClipResult_action__1KeNl > span {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold; }
      .ClipResult_wrapper__3jgRw .ClipResult_clipResultItem__oNhZt .ClipResult_action__1KeNl > button {
        width: 150px; }

.WordFrequency_wrapper__99cfS {
  width: 30%;
  margin: auto;
  min-width: 380px;
  margin-bottom: 20px;
  padding-top: 20px; }

.WordFrequencyTable_wrapper__2pfha .WordFrequencyTable_searchbar__Tuhvf {
  flex: 1 1;
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #03a9f4;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-bottom: 10px; }
  .WordFrequencyTable_wrapper__2pfha .WordFrequencyTable_searchbar__Tuhvf:active {
    background-color: #ffffff; }
  .WordFrequencyTable_wrapper__2pfha .WordFrequencyTable_searchbar__Tuhvf .WordFrequencyTable_iconSearch__J3pzO {
    color: #03a9f4;
    font-size: 18px;
    margin-right: 5px; }
  .WordFrequencyTable_wrapper__2pfha .WordFrequencyTable_searchbar__Tuhvf input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    border-style: none; }
    .WordFrequencyTable_wrapper__2pfha .WordFrequencyTable_searchbar__Tuhvf input:focus {
      outline: none; }

.SummaryReportFilter_wrapper__1kYHm .SummaryReportFilter_button__1-hMc {
  max-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .SummaryReportFilter_wrapper__1kYHm .SummaryReportFilter_button__1-hMc span {
    width: unset !important; }

.SummaryReportFilter_wrapperModal__3c3Qr .SummaryReportFilter_wrapperLayout__2G8W3 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px; }
  .SummaryReportFilter_wrapperModal__3c3Qr .SummaryReportFilter_wrapperLayout__2G8W3 .SummaryReportFilter_checkboxSection__H7B0z {
    display: flex;
    flex-direction: column; }
    .SummaryReportFilter_wrapperModal__3c3Qr .SummaryReportFilter_wrapperLayout__2G8W3 .SummaryReportFilter_checkboxSection__H7B0z .SummaryReportFilter_checkbox__2O9S1 {
      margin-left: 0px;
      padding-left: 15px; }
      .SummaryReportFilter_wrapperModal__3c3Qr .SummaryReportFilter_wrapperLayout__2G8W3 .SummaryReportFilter_checkboxSection__H7B0z .SummaryReportFilter_checkbox__2O9S1 .SummaryReportFilter_name__33X_u {
        font-size: 16px; }

.SummaryReportFilter_wrapperModal__3c3Qr .SummaryReportFilter_footer__3FBe4 {
  display: flex;
  justify-content: center; }

.SummaryReportOpenText_wrapper__1d2X3 {
  padding: 20px;
  margin-bottom: 20px;
  z-index: 1; }
  .SummaryReportOpenText_wrapper__1d2X3 .SummaryReportOpenText_columns__-XXlK {
    flex: 1 1;
    padding-left: 20px;
    min-width: 50%; }
    .SummaryReportOpenText_wrapper__1d2X3 .SummaryReportOpenText_columns__-XXlK .SummaryReportOpenText_typeReport__3VtWP {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600; }
    .SummaryReportOpenText_wrapper__1d2X3 .SummaryReportOpenText_columns__-XXlK .SummaryReportOpenText_questionTitle__-wixR {
      font-size: 16px;
      font-weight: 400; }
    .SummaryReportOpenText_wrapper__1d2X3 .SummaryReportOpenText_columns__-XXlK .SummaryReportOpenText_subTypeReport__361K6 {
      display: flex;
      justify-content: center;
      margin-bottom: 10px; }

.SummaryReportFillInTheBlank_wrapper__3d5rR {
  padding: 20px;
  margin-bottom: 20px;
  z-index: 1; }
  .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_columns__2joXz {
    flex: 1 1;
    padding-left: 20px;
    min-width: 50%; }
    .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_columns__2joXz .SummaryReportFillInTheBlank_typeReport__1fu2T {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600; }
    .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_columns__2joXz .SummaryReportFillInTheBlank_questionTitle__2ii36 {
      font-size: 16px;
      font-weight: 400; }
    .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_columns__2joXz .SummaryReportFillInTheBlank_subTypeReport__2ZC-T {
      display: flex;
      justify-content: center;
      margin-bottom: 10px; }

.SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_columns__2joXz .SummaryReportFillInTheBlank_tableSummary__3HRJS {
  width: 50%;
  margin: auto; }

.SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_answerContainer__AQz_L {
  margin-top: 20px; }
  .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_answerContainer__AQz_L .SummaryReportFillInTheBlank_answerItem__39wQR {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_answerContainer__AQz_L .SummaryReportFillInTheBlank_answerItem__39wQR span {
      margin-left: 5px; }

.SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_userMediaContainer__2Z4S- {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap; }
  .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_userMediaContainer__2Z4S- .SummaryReportFillInTheBlank_videoDownload__QEqHM {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_userMediaContainer__2Z4S- .SummaryReportFillInTheBlank_videoDownload__QEqHM > button {
      margin-top: 10px;
      width: 100%;
      max-width: 150px; }
  .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_userMediaContainer__2Z4S- .SummaryReportFillInTheBlank_userMedia__1kygG {
    height: 150px;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: auto;
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin-top: 10px; }

.SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_feedbackContainer__1vAqa {
  margin-top: 20px; }
  .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_feedbackContainer__1vAqa h4 {
    margin-bottom: 0px;
    margin-right: 10px; }
  .SummaryReportFillInTheBlank_wrapper__3d5rR .SummaryReportFillInTheBlank_feedbackContainer__1vAqa .SummaryReportFillInTheBlank_feedback__15GL3 {
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid #dddddd;
    margin-top: 10px; }

.SummaryReportInstructionChoice_wrapper__2_6Qx {
  padding: 20px;
  margin-bottom: 20px;
  z-index: 1; }
  .SummaryReportInstructionChoice_wrapper__2_6Qx .SummaryReportInstructionChoice_columns__zMiUT {
    flex: 1 1;
    padding-left: 20px;
    min-width: 50%; }
    .SummaryReportInstructionChoice_wrapper__2_6Qx .SummaryReportInstructionChoice_columns__zMiUT .SummaryReportInstructionChoice_typeReport__2UZij {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600; }
    .SummaryReportInstructionChoice_wrapper__2_6Qx .SummaryReportInstructionChoice_columns__zMiUT .SummaryReportInstructionChoice_questionTitle__2RnWS {
      font-size: 16px;
      font-weight: 400; }
    .SummaryReportInstructionChoice_wrapper__2_6Qx .SummaryReportInstructionChoice_columns__zMiUT .SummaryReportInstructionChoice_subTypeReport__3NSh- {
      display: flex;
      justify-content: center;
      margin-bottom: 10px; }

.ReportDetailsContainer_wrapper__37IX- {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_logo__26124 {
    width: 250px;
    margin-bottom: 20px; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_active__NFCDh {
    color: #1890ff; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_inactive__1jB8R {
    color: #ff4d4f; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_btnDownload__2OZy_ {
    width: 150px;
    margin-right: 10px; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_downloadContent__3_ov3 {
    display: flex;
    justify-content: center;
    align-items: center; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_btnCancel__3GqDh {
    width: 100px; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_spinLoader__miiYj {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: ReportDetailsContainer_spin__31d5j 2s linear infinite;
            animation: ReportDetailsContainer_spin__31d5j 2s linear infinite; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_selector__LDsh4 {
    display: flex;
    flex-direction: row;
    padding: 4px;
    z-index: 2; }
    .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_selector__LDsh4 span {
      padding: 6px;
      width: 100px; }
    .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_selector__LDsh4 .ReportDetailsContainer_select__3_X4T {
      flex: 1 1;
      max-width: 400px;
      padding-right: 10px; }
      .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_selector__LDsh4 .ReportDetailsContainer_select__3_X4T span {
        background-color: #ffffff; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_userMediaContainer__3VDW8 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_userMediaContainer__3VDW8 .ReportDetailsContainer_videoDownload__2xOmk {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_userMediaContainer__3VDW8 .ReportDetailsContainer_videoDownload__2xOmk > button {
        margin-top: 10px;
        width: 100%;
        max-width: 150px; }
    .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_userMediaContainer__3VDW8 .ReportDetailsContainer_userMedia__2p-IN {
      height: 150px;
      width: 250px;
      margin-left: 10px;
      margin-right: 10px;
      overflow: auto;
      object-fit: contain;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
      margin-top: 10px; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_cellHeight__2lalC {
    height: 1px;
    vertical-align: top; }
    .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_cellHeight__2lalC .ReportDetailsContainer_transcriptionText__2eh8p {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_questionContainer__3t0to {
    padding: 20px;
    margin-bottom: 20px;
    z-index: 1; }
    .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_questionContainer__3t0to .ReportDetailsContainer_feedbackContainer__1tzdD {
      margin-top: 20px; }
      .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_questionContainer__3t0to .ReportDetailsContainer_feedbackContainer__1tzdD h4 {
        margin-bottom: 0px;
        margin-right: 10px; }
      .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_questionContainer__3t0to .ReportDetailsContainer_feedbackContainer__1tzdD .ReportDetailsContainer_feedback__2laTM {
        width: 100%;
        font-size: 16px;
        padding: 5px 10px;
        border: 1px solid #dddddd;
        margin-top: 10px; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_subHeader__wI7VN {
    display: flex; }
  .ReportDetailsContainer_wrapper__37IX- .ReportDetailsContainer_answered__1K0kJ {
    font-weight: bold; }

@-webkit-keyframes ReportDetailsContainer_spin__31d5j {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes ReportDetailsContainer_spin__31d5j {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.ReportAnswersContainer_wrapper__1jfpl {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;
  width: 100%;
  height: 100%; }
  .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_spin__LzKbG {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_header__7KkLL {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_header__7KkLL h2 {
      flex: 1 1;
      margin-bottom: 0px;
      margin-right: 10px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_header__7KkLL .ReportAnswersContainer_headerBtnGroup__1oIFx {
      display: flex;
      align-items: center; }
      .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_header__7KkLL .ReportAnswersContainer_headerBtnGroup__1oIFx .ReportAnswersContainer_headerBtn__VXIJs {
        width: 150px;
        margin: 0px 5px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_header__7KkLL .ReportAnswersContainer_markCompleted__kSnKb {
      width: 150px;
      height: 32px;
      line-height: 30px;
      color: #ffffff;
      background-color: #1890ff;
      border-color: #1890ff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      border: 1px solid transparent;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      padding: 0 15px;
      font-size: 14px;
      border-radius: 4px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_header__7KkLL .ReportAnswersContainer_markRejected__3mIf- {
      width: 150px;
      height: 32px;
      line-height: 30px;
      color: #ffffff;
      background-color: #ff4d4f;
      border-color: #ff4d4f;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      border: 1px solid transparent;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      padding: 0 15px;
      font-size: 14px;
      border-radius: 4px; }
  .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-bottom: 20px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_media__1S1hJ {
      width: 400px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_typeReport__KuI6J {
      display: flex;
      justify-content: left;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_questionTitle__2ATuG {
      font-size: 16px;
      font-weight: 400; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_answerContainer__wvzJ2 {
      margin-top: 20px; }
      .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_answerContainer__wvzJ2 .ReportAnswersContainer_answerItem__2Ci4b {
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
        .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_answerContainer__wvzJ2 .ReportAnswersContainer_answerItem__2Ci4b span {
          margin-left: 5px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h {
      display: flex;
      margin-top: 20px;
      overflow: auto;
      width: 100%;
      padding-bottom: 10px; }
      .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h .ReportAnswersContainer_overflow__32Ybw {
        margin-top: 3px;
        margin-left: 3px;
        display: flex; }
        .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h .ReportAnswersContainer_overflow__32Ybw .ReportAnswersContainer_userMedia__N206D {
          height: 150px;
          margin-right: 10px;
          width: 250px;
          overflow: auto;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16); }
        .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h .ReportAnswersContainer_overflow__32Ybw .ReportAnswersContainer_userMediaSignature__1Es2Z {
          height: 150px;
          width: 250px;
          overflow: auto;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
          object-fit: contain; }
        .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h .ReportAnswersContainer_overflow__32Ybw .ReportAnswersContainer_videoGroup__3YoYG {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 10px; }
          .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h .ReportAnswersContainer_overflow__32Ybw .ReportAnswersContainer_videoGroup__3YoYG .ReportAnswersContainer_dateTime__1RUGg {
            align-self: flex-start;
            margin-bottom: 4px;
            font-size: 12px;
            color: gray; }
          .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h .ReportAnswersContainer_overflow__32Ybw .ReportAnswersContainer_videoGroup__3YoYG > button {
            margin-top: 10px;
            width: 100%;
            max-width: 150px; }
          .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_userMediaContainer__TT47h .ReportAnswersContainer_overflow__32Ybw .ReportAnswersContainer_videoGroup__3YoYG > video {
            margin: auto; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_feedbackContainer__1TXCH {
      margin-top: 20px; }
      .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_feedbackContainer__1TXCH .ReportAnswersContainer_feedbackContainerTitle__19QYg {
        display: flex; }
      .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_feedbackContainer__1TXCH h4 {
        margin-bottom: 0px;
        margin-right: 10px; }
      .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_questionContainer__1yHk6 .ReportAnswersContainer_feedbackContainer__1TXCH .ReportAnswersContainer_feedback__2vz5l {
        width: 100%;
        font-size: 16px;
        padding: 5px 10px;
        border: 1px solid #dddddd;
        margin-top: 10px; }
  .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_btnGroup__16sAJ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 50px; }
    .ReportAnswersContainer_wrapper__1jfpl .ReportAnswersContainer_btnGroup__16sAJ .ReportAnswersContainer_btn__3Ewye {
      width: 100px;
      margin: 0px 10px; }

.FeedbackConversation_wrapper__2Oc4X .FeedbackConversation_paragraph__3Zvxi {
  font-size: 16px;
  white-space: pre-wrap; }

.FeedbackConversation_wrapper__2Oc4X .FeedbackConversation_createAt__3VUR- {
  color: #a5a4a4; }

.ParticipantListContainer_wrapper__3P_IN {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ .ParticipantListContainer_searchbar__306kM {
      flex: 1 1;
      height: 40px;
      border-radius: 20px;
      background-color: #ffffff;
      border: 1px solid #03a9f4;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px; }
      .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ .ParticipantListContainer_searchbar__306kM:active {
        background-color: #ffffff; }
      .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ .ParticipantListContainer_searchbar__306kM .ParticipantListContainer_iconSearch__3uuBn {
        color: #03a9f4;
        font-size: 18px;
        margin-right: 5px; }
      .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ .ParticipantListContainer_searchbar__306kM input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-style: none; }
        .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ .ParticipantListContainer_searchbar__306kM input:focus {
          outline: none; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ div {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer; }
      .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ div .ParticipantListContainer_icon__3xAUU {
        margin-right: 5px; }
      .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ div:focus {
        outline: none; }
      .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_top__EanGQ div:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .ParticipantListContainer_wrapper__3P_IN table {
    width: 100%;
    border-collapse: collapse; }
  .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_header__3VuFP {
    background: #03a9f4; }
  .ParticipantListContainer_wrapper__3P_IN th {
    height: 40px;
    padding: 10px 10px;
    color: #ffffff; }
  .ParticipantListContainer_wrapper__3P_IN td {
    padding: 10px 10px; }
  .ParticipantListContainer_wrapper__3P_IN table,
  .ParticipantListContainer_wrapper__3P_IN th,
  .ParticipantListContainer_wrapper__3P_IN td {
    border: 1px solid #dddddd;
    text-align: center; }
  .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_iconPencil__1bDmr {
    margin-right: 5px; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_iconPencil__1bDmr:active {
      opacity: 0.5; }
  .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_iconTrash__2UoI9 {
    margin-left: 5px;
    color: #b71c1c; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_iconTrash__2UoI9:active {
      opacity: 0.5; }
  .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_iconRefresh__17A0C {
    margin-left: 5px;
    color: #03a9f4; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_iconRefresh__17A0C:active {
      opacity: 0.5; }
  .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_pagination__34mLc {
    margin: 30px;
    display: inline-flex;
    list-style: none;
    position: fixed;
    bottom: 0; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_pagination__34mLc a.ParticipantListContainer_active__3ZZvA {
      background-color: #03a9f4;
      color: white;
      border: 1px solid #03a9f4; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_pagination__34mLc a:hover:not(.ParticipantListContainer_active__3ZZvA) {
      background-color: #ddd; }
    .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_pagination__34mLc a {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color 0.3s;
      border: 1px solid #ddd; }
  .ParticipantListContainer_wrapper__3P_IN .ParticipantListContainer_activeItem__2HMwt {
    background-color: #03a9f4;
    color: white;
    border: 1px solid #03a9f4; }

.CampaignAnalyticsContainer_wrapper__3xlb4 {
  padding: 20px;
  font-size: 16px; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_top__3cqd- {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_basicTableContainer__5NvvY {
    padding: 20px 10px;
    border: 1px solid #dddddd; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_basicTableContainer__5NvvY .CampaignAnalyticsContainer_basicTable__2J5zR {
      width: 100%; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_basicTableContainer__5NvvY .CampaignAnalyticsContainer_basicTable__2J5zR tr {
        display: flex;
        justify-content: space-between; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_basicTableContainer__5NvvY .CampaignAnalyticsContainer_basicTable__2J5zR td {
        flex: 1 1; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_inputItem__3cS1C {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_inputItem__3cS1C span {
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_inputItem__3cS1C input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_inputItem__3cS1C input:disabled {
        background-color: #eeeeee; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_inputItem__3cS1C .CampaignAnalyticsContainer_select__3HyQf {
      height: 40px;
      flex: 1 1;
      max-width: calc(100% - 150px); }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_inputItem__3cS1C .CampaignAnalyticsContainer_select__3HyQf span {
        width: 0px;
        background-color: #ffffff; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_inputItem__3cS1C .CampaignAnalyticsContainer_select__3HyQf .CampaignAnalyticsContainer_questionSelect__1tp04 {
        margin: 0px; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j {
    margin-top: 50px;
    width: 100%; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table {
      width: 100%;
      padding: 20px 10px;
      border: 1px solid #dddddd;
      border-collapse: collapse; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table tr {
        justify-content: space-between;
        border: 1px solid #dddddd; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table th {
        border: 1px solid #dddddd;
        height: 50px;
        line-height: 50px; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table td {
        flex: 1 1;
        border: 1px solid #dddddd;
        text-align: center;
        padding: 10px; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table td .CampaignAnalyticsContainer_previewImage__2Vksq {
          width: 250px;
          height: 150px;
          object-fit: contain; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table .CampaignAnalyticsContainer_select__3HyQf input[type='checkbox'] {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table video {
        width: 300px;
        height: 200px; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table .CampaignAnalyticsContainer_progress__3t-4w {
        font-weight: 400;
        color: #b71c1c; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table .CampaignAnalyticsContainer_progress__3t-4w .CampaignAnalyticsContainer_action__34iAp {
          display: flex;
          align-items: center;
          flex-direction: column; }
          .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table .CampaignAnalyticsContainer_progress__3t-4w .CampaignAnalyticsContainer_action__34iAp button {
            width: 150px; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table .CampaignAnalyticsContainer_completed__2uoNX {
        font-weight: 400;
        color: #03a9f4; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table .CampaignAnalyticsContainer_completed__2uoNX .CampaignAnalyticsContainer_action__34iAp {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_videoTableContainer__NI10j table .CampaignAnalyticsContainer_completed__2uoNX .CampaignAnalyticsContainer_action__34iAp button {
            width: 150px; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC {
    width: 100%;
    margin-top: 50px; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn {
      width: 100%;
      margin-top: 25px; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dddddd; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn table tr,
        .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn table td {
          border: 1px solid #dddddd; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn table td {
          height: 30px;
          padding: 0px 5px;
          cursor: pointer; }
          .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn table td:active {
            opacity: 0.5; }
          .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn table td .CampaignAnalyticsContainer_time__IuCzW {
            margin-top: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 8px; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_transcriptionContainer__2rquC .CampaignAnalyticsContainer_transcriptionTable__clGmn table .CampaignAnalyticsContainer_selectedword__1X1h5 {
          background-color: #03a9f4; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_btnGroup__B5ePS {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_btnGroup__B5ePS .CampaignAnalyticsContainer_btnSave__2tb8e,
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_btnGroup__B5ePS .CampaignAnalyticsContainer_btnSearch__27Jz2 {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer;
      margin: 0px 25px; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_btnGroup__B5ePS .CampaignAnalyticsContainer_btnSave__2tb8e:active,
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_btnGroup__B5ePS .CampaignAnalyticsContainer_btnSearch__27Jz2:active {
        background-color: #03a9f4;
        color: #ffffff; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_btnGroup__B5ePS .CampaignAnalyticsContainer_btnClear__34RhQ {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #b71c1c;
      align-items: center;
      border: 1px solid #b71c1c;
      cursor: pointer;
      margin: 0px 25px; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_btnGroup__B5ePS .CampaignAnalyticsContainer_btnClear__34RhQ:active {
        background-color: #b71c1c;
        color: #ffffff; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_clipListContainer__1bR0S {
    width: 100%; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_clipListContainer__1bR0S .CampaignAnalyticsContainer_clipList__1CD1h {
      padding: 15px;
      background-color: #dddddd; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_clipListContainer__1bR0S .CampaignAnalyticsContainer_clipListItem__dBUff {
      padding: 15px;
      background-color: #ffffff;
      cursor: pointer; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_clipListContainer__1bR0S .CampaignAnalyticsContainer_clipListItem__dBUff:not(:last-child) {
        margin-bottom: 15px; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 .CampaignAnalyticsContainer_clipListContainer__1bR0S .CampaignAnalyticsContainer_clipListItemDragging__126PQ {
      background-color: #eeeeee; }
  .CampaignAnalyticsContainer_wrapper__3xlb4 clipVideo .CampaignAnalyticsContainer_clipResultContainer__3xa4n {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .CampaignAnalyticsContainer_wrapper__3xlb4 clipVideo .CampaignAnalyticsContainer_clipResultContainer__3xa4n .CampaignAnalyticsContainer_clipResultItem__1y32b {
      text-align: center;
      width: calc(33% - 20px);
      margin: 10px; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 clipVideo .CampaignAnalyticsContainer_clipResultContainer__3xa4n .CampaignAnalyticsContainer_clipResultItem__1y32b .CampaignAnalyticsContainer_clipVideo__1p5VV {
        width: 100%;
        height: 300px;
        background-color: lightgrey; }
      .CampaignAnalyticsContainer_wrapper__3xlb4 clipVideo .CampaignAnalyticsContainer_clipResultContainer__3xa4n .CampaignAnalyticsContainer_clipResultItem__1y32b .CampaignAnalyticsContainer_action__34iAp {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 clipVideo .CampaignAnalyticsContainer_clipResultContainer__3xa4n .CampaignAnalyticsContainer_clipResultItem__1y32b .CampaignAnalyticsContainer_action__34iAp > span {
          margin-top: 20px;
          font-size: 20px;
          font-weight: bold; }
        .CampaignAnalyticsContainer_wrapper__3xlb4 clipVideo .CampaignAnalyticsContainer_clipResultContainer__3xa4n .CampaignAnalyticsContainer_clipResultItem__1y32b .CampaignAnalyticsContainer_action__34iAp > button {
          width: 150px; }

.SessionAnalyticsContainer_wrapper__9JKvE {
  padding: 20px;
  font-size: 16px; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_top__2jaLC {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_basicTableContainer__3TKhl {
    padding: 20px 10px;
    border: 1px solid #dddddd; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_basicTableContainer__3TKhl .SessionAnalyticsContainer_basicTable__q6i9C {
      width: 100%; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_basicTableContainer__3TKhl .SessionAnalyticsContainer_basicTable__q6i9C tr {
        display: flex;
        justify-content: space-between; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_basicTableContainer__3TKhl .SessionAnalyticsContainer_basicTable__q6i9C td {
        flex: 1 1; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_inputItem__2myEe {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_inputItem__2myEe span {
      width: 150px;
      text-align: center; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_inputItem__2myEe input {
      flex: 1 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid #dddddd; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_inputItem__2myEe input:disabled {
        background-color: #eeeeee; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_inputItem__2myEe .SessionAnalyticsContainer_select__3YluB {
      height: 40px;
      flex: 1 1; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_inputItem__2myEe .SessionAnalyticsContainer_select__3YluB span {
        width: 0px;
        background-color: #ffffff; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug {
    margin-top: 50px;
    width: 100%; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table {
      width: 100%;
      padding: 20px 10px;
      border: 1px solid #dddddd;
      border-collapse: collapse; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table tr {
        justify-content: space-between;
        border: 1px solid #dddddd; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table th {
        border: 1px solid #dddddd;
        height: 50px;
        line-height: 50px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table td {
        flex: 1 1;
        border: 1px solid #dddddd;
        text-align: center;
        padding: 10px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table .SessionAnalyticsContainer_select__3YluB input[type='checkbox'] {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table video {
        width: 300px;
        height: 200px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table .SessionAnalyticsContainer_progress__3Zbsp {
        font-weight: 400;
        color: #b71c1c; }
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table .SessionAnalyticsContainer_progress__3Zbsp .SessionAnalyticsContainer_action__3D1rT {
          display: flex;
          align-items: center;
          flex-direction: column; }
          .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table .SessionAnalyticsContainer_progress__3Zbsp .SessionAnalyticsContainer_action__3D1rT button {
            width: 150px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table .SessionAnalyticsContainer_completed__ZTkyD {
        font-weight: 400;
        color: #03a9f4; }
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table .SessionAnalyticsContainer_completed__ZTkyD .SessionAnalyticsContainer_action__3D1rT {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_videoTableContainer__3ADug table .SessionAnalyticsContainer_completed__ZTkyD .SessionAnalyticsContainer_action__3D1rT button {
            width: 150px; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD {
    width: 100%;
    margin-top: 50px; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT {
      width: 100%;
      margin-top: 25px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dddddd; }
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT table tr,
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT table td {
          border: 1px solid #dddddd; }
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT table td {
          height: 30px;
          padding: 0px 5px;
          cursor: pointer; }
          .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT table td:active {
            opacity: 0.5; }
          .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT table td .SessionAnalyticsContainer_time__2tu-l {
            margin-top: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 8px; }
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_transcriptionContainer__3ocYD .SessionAnalyticsContainer_transcriptionTable__1dcDT table .SessionAnalyticsContainer_selectedword__3vPQF {
          background-color: #03a9f4; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_btnGroup__2Yq5V {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_btnGroup__2Yq5V .SessionAnalyticsContainer_btnSave__3BFCt,
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_btnGroup__2Yq5V .SessionAnalyticsContainer_btnSearch__fTeuI {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer;
      margin: 0px 25px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_btnGroup__2Yq5V .SessionAnalyticsContainer_btnSave__3BFCt:active,
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_btnGroup__2Yq5V .SessionAnalyticsContainer_btnSearch__fTeuI:active {
        background-color: #03a9f4;
        color: #ffffff; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_btnGroup__2Yq5V .SessionAnalyticsContainer_btnClear__3dyFR {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #b71c1c;
      align-items: center;
      border: 1px solid #b71c1c;
      cursor: pointer;
      margin: 0px 25px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_btnGroup__2Yq5V .SessionAnalyticsContainer_btnClear__3dyFR:active {
        background-color: #b71c1c;
        color: #ffffff; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipListContainer__1fXoO {
    width: 100%; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipListContainer__1fXoO .SessionAnalyticsContainer_clipList__1mhqE {
      padding: 15px;
      background-color: #dddddd; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipListContainer__1fXoO .SessionAnalyticsContainer_clipListItem__3_038 {
      padding: 15px;
      background-color: #ffffff;
      cursor: pointer; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipListContainer__1fXoO .SessionAnalyticsContainer_clipListItem__3_038:not(:last-child) {
        margin-bottom: 15px; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipListContainer__1fXoO .SessionAnalyticsContainer_clipListItemDragging__zelEJ {
      background-color: #eeeeee; }
  .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipResultContainer__2PoOZ {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipResultContainer__2PoOZ .SessionAnalyticsContainer_clipResultItem__3bjli {
      text-align: center;
      width: calc(33% - 20px);
      margin: 10px; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipResultContainer__2PoOZ .SessionAnalyticsContainer_clipResultItem__3bjli .SessionAnalyticsContainer_clipVideo__cBWTX {
        width: 100%;
        height: 300px;
        background-color: lightgrey; }
      .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipResultContainer__2PoOZ .SessionAnalyticsContainer_clipResultItem__3bjli .SessionAnalyticsContainer_action__3D1rT {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipResultContainer__2PoOZ .SessionAnalyticsContainer_clipResultItem__3bjli .SessionAnalyticsContainer_action__3D1rT > span {
          margin-top: 20px;
          font-size: 20px;
          font-weight: bold; }
        .SessionAnalyticsContainer_wrapper__9JKvE .SessionAnalyticsContainer_clipResultContainer__2PoOZ .SessionAnalyticsContainer_clipResultItem__3bjli .SessionAnalyticsContainer_action__3D1rT > button {
          width: 150px; }

.ChatListContainer_wrapper__Z312V {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px; }
    .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchContainer__2UkBB {
      flex: 1 1;
      display: flex;
      justify-content: space-between; }
      .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchContainer__2UkBB .ChatListContainer_searchbar__1_TVp {
        flex: 1 1;
        height: 40px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #03a9f4;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px; }
        .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchContainer__2UkBB .ChatListContainer_searchbar__1_TVp:active {
          background-color: #ffffff; }
        .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchContainer__2UkBB .ChatListContainer_searchbar__1_TVp .ChatListContainer_iconSearch__oTR7Q {
          color: #03a9f4;
          font-size: 18px;
          margin-right: 5px; }
        .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchContainer__2UkBB .ChatListContainer_searchbar__1_TVp input {
          width: 100%;
          height: 40px;
          font-size: 16px;
          border-style: none; }
          .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchContainer__2UkBB .ChatListContainer_searchbar__1_TVp input:focus {
            outline: none; }
    .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchfilters__2MQsk {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchfilters__2MQsk .ChatListContainer_filter__3zs81 {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer; }
        .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchfilters__2MQsk .ChatListContainer_filter__3zs81 input {
          margin-right: 5px; }
      .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_searchfilters__2MQsk .ChatListContainer_moreSpace__2UyLg {
        width: 150px;
        visibility: hidden; }
    .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_btnAdd__VcBZW {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer; }
      .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_btnAdd__VcBZW .ChatListContainer_icon__1Mqs2 {
        margin-right: 5px; }
      .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_btnAdd__VcBZW:focus {
        outline: none; }
      .ChatListContainer_wrapper__Z312V .ChatListContainer_top__2nswT .ChatListContainer_btnAdd__VcBZW:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .ChatListContainer_wrapper__Z312V table {
    width: 100%;
    border-collapse: collapse; }
  .ChatListContainer_wrapper__Z312V .ChatListContainer_header__UApPY {
    background: #03a9f4; }
  .ChatListContainer_wrapper__Z312V th {
    height: 40px;
    padding: 10px 10px;
    color: #ffffff; }
  .ChatListContainer_wrapper__Z312V td {
    padding: 10px 10px; }
  .ChatListContainer_wrapper__Z312V .ChatListContainer_name__2ff9X {
    text-decoration: underline;
    color: #03a9f4;
    cursor: pointer; }

.ChatDetailsContainer_wrapper__1oZuY {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px; }
  .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 {
    flex: 1 1;
    padding: 20px;
    overflow: auto; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR {
      display: flex;
      margin-bottom: 10px; }
      .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh {
        display: flex;
        flex-direction: row;
        width: 40%;
        justify-content: flex-end; }
        .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_adminAvatar__1uh0J {
          margin-right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden; }
        .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_userAvatar__Sb1Vh {
          margin-left: 10px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden; }
        .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageBody__3ign9 {
          flex: 1 1;
          display: flex;
          flex-direction: column;
          font-size: 18px;
          line-height: 20px;
          padding: 5px 10px;
          border-radius: 5px;
          word-break: break-word; }
          .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageBody__3ign9 .ChatDetailsContainer_messageVideo__3dYOs {
            width: calc(100%-20px);
            height: 200px;
            margin: 10px; }
          .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageBody__3ign9 .ChatDetailsContainer_messageImage__3G7Gn {
            width: 90%;
            height: 200px;
            margin: 10px;
            object-fit: contain; }
          .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageBody__3ign9 span {
            margin-top: 10px;
            font-size: 10px; }
        .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageLBody__36jmv {
          justify-content: flex-start;
          background-color: orange; }
          .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageLBody__36jmv span {
            text-align: left; }
        .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageRBody__3qLzR {
          justify-content: flex-end;
          background-color: #dddddd;
          margin-right: 10px;
          text-align: left; }
          .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageContainer__2JCYR .ChatDetailsContainer_messageItem__2bNqh .ChatDetailsContainer_messageRBody__3qLzR span {
            text-align: right; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageRContainer___BD50 {
      justify-content: flex-end; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_messagePane__15P51 .ChatDetailsContainer_messageLContainer__24Tqs {
      justify-content: flex-start; }
  .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_empty__1XXNL {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1; }
  .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi {
    border-top: 1px solid #dddddd;
    display: flex;
    align-items: center;
    padding: 10px 20px; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_actionBtn__2prtn {
      margin-right: 10px;
      cursor: pointer; }
      .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_actionBtn__2prtn i {
        font-size: 24px;
        color: #03a9f4; }
      .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_actionBtn__2prtn:active {
        opacity: 0.5; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_menu__1JI1e {
      display: flex;
      flex-direction: column;
      background: #ffffff; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_menu-item__3hHr_ {
      cursor: pointer;
      padding: 15px 20px;
      border-bottom: 1px solid #bbbbbb; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_menu-item__3hHr_:hover {
      color: #03a9f4; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi textarea,
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi input {
      flex: 1 1;
      height: 30px;
      font-size: 16px;
      padding: 0px 10px; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_sendBtn__3AA2P {
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      margin-left: 10px;
      display: flex; }
      .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_sendBtn__3AA2P:active {
        background-color: #03a9f4;
        color: #ffffff; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_inputContainer__1xCJi .ChatDetailsContainer_file__3285H {
      display: none; }
  .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_statusbar__3d7Y0 {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: darkgrey;
    margin-bottom: 10px; }
    .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_statusbar__3d7Y0 .ChatDetailsContainer_clearBtn__2FxKH {
      padding: 5px 10px;
      font-size: 12px;
      color: #b71c1c;
      font-weight: bold;
      cursor: pointer; }
      .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_statusbar__3d7Y0 .ChatDetailsContainer_clearBtn__2FxKH i {
        margin-left: 5px; }
      .ChatDetailsContainer_wrapper__1oZuY .ChatDetailsContainer_statusbar__3d7Y0 .ChatDetailsContainer_clearBtn__2FxKH:active {
        opacity: 0.5; }

.ChatDetailsContainer_wrapperSpin__30vU5 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.Router_wrapper__lloMk {
  width: 100%;
  height: 100vh; }
  .Router_wrapper__lloMk header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #03a9f4;
    color: white;
    padding: 16px 24px;
    font-size: 24px;
    z-index: 5555 !important;
    position: fixed;
    width: 100%;
    top: 0px; }
    .Router_wrapper__lloMk header .Router_logoutBtn__2HeY8 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer; }
      .Router_wrapper__lloMk header .Router_logoutBtn__2HeY8 i {
        margin-right: 5px; }
      .Router_wrapper__lloMk header .Router_logoutBtn__2HeY8:active {
        opacity: 0.5; }
  .Router_wrapper__lloMk .Router_container__2JV9h {
    height: 100%;
    display: flex; }
    .Router_wrapper__lloMk .Router_container__2JV9h .Router_sidebar__1MLRZ {
      width: 200px;
      padding: 16px;
      position: fixed;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;
      overflow-y: auto;
      height: calc(100% - 68px);
      top: 68px;
      z-index: 10; }
      .Router_wrapper__lloMk .Router_container__2JV9h .Router_sidebar__1MLRZ .Router_menuitem__2PhYa {
        display: flex;
        flex-direction: column;
        padding: 16px 0px;
        color: #757575;
        text-decoration: none;
        font-size: 18px; }
        .Router_wrapper__lloMk .Router_container__2JV9h .Router_sidebar__1MLRZ .Router_menuitem__2PhYa:active {
          opacity: 0.5; }
        .Router_wrapper__lloMk .Router_container__2JV9h .Router_sidebar__1MLRZ .Router_menuitem__2PhYa .Router_submenuitem__3_5qF {
          color: #757575;
          text-decoration: none;
          font-size: 18px;
          margin-top: 32px;
          margin-left: 16px; }
      .Router_wrapper__lloMk .Router_container__2JV9h .Router_sidebar__1MLRZ .Router_menuitem-selected__2NYxF {
        color: #555555;
        font-weight: bold; }
      .Router_wrapper__lloMk .Router_container__2JV9h .Router_sidebar__1MLRZ .Router_menucategory__2wZ_w {
        color: #aaaaaa; }
        .Router_wrapper__lloMk .Router_container__2JV9h .Router_sidebar__1MLRZ .Router_menucategory__2wZ_w:active {
          opacity: 1; }
    .Router_wrapper__lloMk .Router_container__2JV9h .Router_content__Ce9Yv {
      width: calc(100% - 200px);
      margin-top: 68px;
      margin-left: 200px; }

.ManagersContainer_wrapper__19RPM {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px; }
  .ManagersContainer_wrapper__19RPM .ManagersContainer_actionBar__3H0eG {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px; }
    .ManagersContainer_wrapper__19RPM .ManagersContainer_actionBar__3H0eG .ManagersContainer_btnAdd__1uoeh {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: #03a9f4;
      align-items: center;
      border: 1px solid #03a9f4;
      cursor: pointer; }
      .ManagersContainer_wrapper__19RPM .ManagersContainer_actionBar__3H0eG .ManagersContainer_btnAdd__1uoeh .ManagersContainer_icon__1w7fQ {
        margin-right: 5px; }
      .ManagersContainer_wrapper__19RPM .ManagersContainer_actionBar__3H0eG .ManagersContainer_btnAdd__1uoeh:focus {
        outline: none; }
      .ManagersContainer_wrapper__19RPM .ManagersContainer_actionBar__3H0eG .ManagersContainer_btnAdd__1uoeh:active {
        background-color: #03a9f4;
        color: #ffffff; }
  .ManagersContainer_wrapper__19RPM .ManagersContainer_action__2HYlw {
    display: flex;
    justify-content: center; }
    .ManagersContainer_wrapper__19RPM .ManagersContainer_action__2HYlw .ManagersContainer_actionBtn__1hEV2 {
      margin: 0 10px;
      cursor: pointer;
      color: black;
      font-size: 17px; }
      .ManagersContainer_wrapper__19RPM .ManagersContainer_action__2HYlw .ManagersContainer_actionBtn__1hEV2:hover {
        color: blue; }
  .ManagersContainer_wrapper__19RPM .ManagersContainer_input__2Y3cg {
    margin: 5px 0; }

.LoginContainer_wrapper__1_bGw {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; }
  .LoginContainer_wrapper__1_bGw .LoginContainer_loginContainer__2giTG {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: -100px;
    padding: 20px;
    border: 1px #555555;
    border-radius: 1px;
    box-shadow: 2px 3px 2px 3px #dddddd; }
    .LoginContainer_wrapper__1_bGw .LoginContainer_loginContainer__2giTG h2 {
      text-align: center; }
    .LoginContainer_wrapper__1_bGw .LoginContainer_loginContainer__2giTG input {
      height: 40px;
      font-size: 16px;
      line-height: 40px;
      padding: 0px 10px;
      margin-bottom: 10px; }
    .LoginContainer_wrapper__1_bGw .LoginContainer_loginContainer__2giTG .LoginContainer_btn__1QuWm {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 150px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      margin-top: 10px;
      text-align: center;
      color: #ffffff;
      align-items: center;
      border: 1px solid #03a9f4;
      background-color: #03a9f4;
      cursor: pointer; }
      .LoginContainer_wrapper__1_bGw .LoginContainer_loginContainer__2giTG .LoginContainer_btn__1QuWm:active {
        background-color: #ffffff;
        color: #03a9f4; }
  .LoginContainer_wrapper__1_bGw .LoginContainer_spinLoader__1ChAl {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: LoginContainer_spin__aNai9 2s linear infinite;
            animation: LoginContainer_spin__aNai9 2s linear infinite; }

@-webkit-keyframes LoginContainer_spin__aNai9 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes LoginContainer_spin__aNai9 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.AnswerOpenCampaignContainer_wrapper__3Ktgt {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 0 50px;
  min-height: 100%;
  min-width: 100%;
  background: #f0f2f5;
  overflow: hidden; }
  @media (max-width: 576px) {
    .AnswerOpenCampaignContainer_wrapper__3Ktgt {
      padding: 0 20px; } }
  .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- {
    width: 80%;
    background-color: #ffffff; }
    @media (max-width: 576px) {
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- {
        width: 100%; } }
    .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_header__2OtYC {
      background-color: #03a9f4;
      min-height: 100px;
      height: auto;
      font-size: 36px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      line-height: normal;
      align-items: center; }
      @media (max-width: 768px) {
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_header__2OtYC {
          font-size: 25px;
          padding: 0 20px; } }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_header__2OtYC .AnswerOpenCampaignContainer_text__3UQWK {
        display: block; }
    .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm {
      padding: 1.5em 30px 2em; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_completeCampaign__1DDNB {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_completeCampaign__1DDNB > i {
          font-size: 30px; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_completeCampaign__1DDNB > div {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_expireTime__6rhbS {
        display: flex;
        justify-content: center;
        align-items: center; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_alert__17v0f {
        margin-bottom: 20px; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_action__1Z04C {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_action__1Z04C button:not(:first-of-type) {
          margin-left: 10px; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_action__1Z04C .AnswerOpenCampaignContainer_backBtn__2TPC_ {
          background: #d9d9d9; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_action__1Z04C .AnswerOpenCampaignContainer_backBtn__2TPC_:hover, .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_action__1Z04C .AnswerOpenCampaignContainer_backBtn__2TPC_:active, .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_action__1Z04C .AnswerOpenCampaignContainer_backBtn__2TPC_:focus {
            color: #262626;
            border-color: #8c8c8c; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_progress__1rJeN {
        display: flex;
        justify-content: center;
        flex: 1 1; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_progress__1rJeN .AnswerOpenCampaignContainer_inner__1U9yp {
          width: 300px;
          margin-top: 10px; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_errInfoReject__8XXX8 {
        display: flex; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_errInfoReject__8XXX8 span {
          max-width: 300px;
          width: 300px;
          margin: auto;
          color: red;
          font-weight: 600;
          display: block;
          text-align: center; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerUser__2SNAL {
        max-width: 300px;
        margin: auto; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerUser__2SNAL .AnswerOpenCampaignContainer_logo__1Hupe {
          display: flex;
          justify-content: center; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerUser__2SNAL .AnswerOpenCampaignContainer_logo__1Hupe img {
            width: 250px;
            max-height: 250px;
            object-fit: contain;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16); }
            @media (max-width: 576px) {
              .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerUser__2SNAL .AnswerOpenCampaignContainer_logo__1Hupe img {
                width: 100%; } }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerUser__2SNAL .AnswerOpenCampaignContainer_title__1OjqS {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: justify; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerUser__2SNAL .AnswerOpenCampaignContainer_shareYour__2AHoP {
          color: #555555;
          margin-bottom: 10px;
          display: block; }
      .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_typeQuestion__2zYsK {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_typeQuestion__2zYsK .AnswerOpenCampaignContainer_questionTitle__MP_83 {
            font-weight: 500;
            font-size: 25px;
            min-width: 300px;
            max-width: 300px;
            text-align: start;
            word-break: break-word; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_typeQuestion__2zYsK .AnswerOpenCampaignContainer_type__3wQ0Q {
            text-align: left;
            color: #555555;
            max-width: 300px;
            width: 100%;
            display: block;
            margin-top: 10px; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_typeQuestion__2zYsK .AnswerOpenCampaignContainer_select__3_V-K {
            line-height: 20px;
            display: flex;
            align-items: baseline;
            margin-top: 5px;
            cursor: default; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_typeQuestion__2zYsK .AnswerOpenCampaignContainer_select__3_V-K:first-of-type {
              margin-top: 15px; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_typeQuestion__2zYsK .AnswerOpenCampaignContainer_select__3_V-K input[type='checkbox'] {
              -webkit-transform: scale(1.25);
                      transform: scale(1.25);
              margin-right: 10px;
              min-width: 16px; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_typeQuestion__2zYsK .AnswerOpenCampaignContainer_select__3_V-K span {
              font-weight: 400;
              font-size: 16px; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_youtubeView__frNNr {
          position: relative; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_youtubeView__frNNr .AnswerOpenCampaignContainer_media__2opq_ {
            width: 300px;
            margin-top: 10px; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_youtubeView__frNNr div {
            position: absolute;
            top: 10px;
            right: 0px;
            width: 30px; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_youtubeView__frNNr div i {
              font-size: 30px;
              color: #b71c1c; }
              .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_youtubeView__frNNr div i:active {
                opacity: 0.5; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaView__2GFq3 {
          position: relative; }
          @media (max-width: 576px) {
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaView__2GFq3 {
              width: 100%; } }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaView__2GFq3 .AnswerOpenCampaignContainer_media__2opq_ {
            height: 200px;
            width: 300px;
            object-fit: contain;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
            margin: 10px 5px 0 5px;
            display: flex;
            justify-content: center;
            align-items: center; }
            @media (max-width: 576px) {
              .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaView__2GFq3 .AnswerOpenCampaignContainer_media__2opq_ {
                width: 100%; } }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaView__2GFq3 .AnswerOpenCampaignContainer_iconRemove__2T31F {
            position: absolute;
            top: 10px;
            right: 3px;
            width: 30px;
            cursor: pointer; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaView__2GFq3 .AnswerOpenCampaignContainer_iconRemove__2T31F i {
              font-size: 30px;
              color: #b71c1c; }
              .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaView__2GFq3 .AnswerOpenCampaignContainer_iconRemove__2T31F i:active {
                opacity: 0.5; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_upload__1u_4h {
          margin-top: 10px; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_upload__1u_4h .AnswerOpenCampaignContainer_btnUpload__1eaFc {
            height: 40px;
            border-radius: 3px;
            background-color: #03a9f4;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_upload__1u_4h .AnswerOpenCampaignContainer_btnUpload__1eaFc .AnswerOpenCampaignContainer_icon__13NL3 {
              margin-right: 5px; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_upload__1u_4h .AnswerOpenCampaignContainer_btnUpload__1eaFc:active {
              background-color: #ffffff;
              color: #03a9f4; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_upload__1u_4h .AnswerOpenCampaignContainer_img__3wm0E {
            object-fit: cover;
            width: 40px;
            height: 30px; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_nomedia__1xCGY {
          margin-top: 10px; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_mediaRow__3IBob {
          display: flex; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_buttonSelected__11QjF {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_buttonSelected__11QjF .AnswerOpenCampaignContainer_btnOption__HBnek {
            width: 300px;
            height: 35px;
            flex: 1 1;
            border-radius: 2px;
            line-height: 40px;
            font-weight: 600;
            text-align: start;
            margin-top: 10px; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_buttonSelected__11QjF .AnswerOpenCampaignContainer_btnOption__HBnek.AnswerOpenCampaignContainer_active__-HGi- {
              background-color: #40a9ff; }
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_buttonSelected__11QjF .AnswerOpenCampaignContainer_btnOption__HBnek .AnswerOpenCampaignContainer_rowRanking__1OjyC {
              display: flex;
              justify-content: space-between; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_feedback__1N4X7 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 10px; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_feedback__1N4X7 .AnswerOpenCampaignContainer_feedbackTitle__1B9Vq {
            max-width: 300px;
            width: 300px;
            color: red;
            font-weight: 600;
            font-size: 18px; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_feedback__1N4X7 span {
            max-width: 300px;
            width: 300px;
            display: block; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_textarea__3hbpi {
          resize: none;
          width: 300px;
          max-width: 300px;
          margin-top: 10px;
          color: #41444b; }
          @media (max-width: 576px) {
            .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_textarea__3hbpi {
              width: 100%; } }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_answer__AAftk {
          display: flex;
          flex-direction: column;
          min-width: 300px;
          max-width: 300px; }
          .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_answer__AAftk .AnswerOpenCampaignContainer_title__1OjqS {
            font-weight: 500; }
        .AnswerOpenCampaignContainer_wrapper__3Ktgt .AnswerOpenCampaignContainer_layout__2ANI- .AnswerOpenCampaignContainer_content__3KzHm .AnswerOpenCampaignContainer_answerStep__S4qnR .AnswerOpenCampaignContainer_question__2ZpiF .AnswerOpenCampaignContainer_shareYour__2AHoP {
          color: #03a9f4;
          display: block; }

.VideoCapture_wrapper__1z39H .VideoCapture_webcam__1j4HM video {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.VideoCapture_wrapper__1z39H .VideoCapture_capture__3CFap {
  display: flex;
  flex-direction: column; }
  .VideoCapture_wrapper__1z39H .VideoCapture_capture__3CFap .VideoCapture_actionCapture__3vIso {
    display: flex;
    flex-direction: row; }
  .VideoCapture_wrapper__1z39H .VideoCapture_capture__3CFap .VideoCapture_capturePhoto__1NWod {
    margin-left: 10px; }

.MediaPreviewItem_wrapper__16mpg .MediaPreviewItem_mediaView__253Z6 {
  position: relative;
  width: 310px;
  margin: auto; }
  @media (max-width: 576px) {
    .MediaPreviewItem_wrapper__16mpg .MediaPreviewItem_mediaView__253Z6 {
      width: 100%; } }
  .MediaPreviewItem_wrapper__16mpg .MediaPreviewItem_mediaView__253Z6 .MediaPreviewItem_media__2P9oa {
    height: 200px;
    width: 300px;
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin: 10px 5px 0 5px; }
    @media (max-width: 576px) {
      .MediaPreviewItem_wrapper__16mpg .MediaPreviewItem_mediaView__253Z6 .MediaPreviewItem_media__2P9oa {
        width: 100%; } }
  .MediaPreviewItem_wrapper__16mpg .MediaPreviewItem_mediaView__253Z6 .MediaPreviewItem_iconRemove__bKFJ8 {
    position: absolute;
    top: 10px;
    right: 3px;
    width: 30px;
    cursor: pointer; }
    .MediaPreviewItem_wrapper__16mpg .MediaPreviewItem_mediaView__253Z6 .MediaPreviewItem_iconRemove__bKFJ8 i {
      font-size: 30px;
      color: #b71c1c; }
      .MediaPreviewItem_wrapper__16mpg .MediaPreviewItem_mediaView__253Z6 .MediaPreviewItem_iconRemove__bKFJ8 i:active {
        opacity: 0.5; }

.ShowMediaHeader_youtubeView__2DsvC {
  position: relative; }
  .ShowMediaHeader_youtubeView__2DsvC .ShowMediaHeader_media__CZvlE {
    width: 300px;
    margin-top: 10px; }
  .ShowMediaHeader_youtubeView__2DsvC div {
    position: absolute;
    top: 10px;
    right: 0px;
    width: 30px; }
    .ShowMediaHeader_youtubeView__2DsvC div i {
      font-size: 30px;
      color: #b71c1c; }
      .ShowMediaHeader_youtubeView__2DsvC div i:active {
        opacity: 0.5; }

.ShowMediaHeader_mediaView__2CkRD {
  position: relative; }
  @media (max-width: 576px) {
    .ShowMediaHeader_mediaView__2CkRD {
      width: 100%; } }
  .ShowMediaHeader_mediaView__2CkRD .ShowMediaHeader_media__CZvlE {
    height: 200px;
    width: 300px;
    object-fit: contain;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    margin: 10px 5px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 576px) {
      .ShowMediaHeader_mediaView__2CkRD .ShowMediaHeader_media__CZvlE {
        width: 100%; } }
  .ShowMediaHeader_mediaView__2CkRD .ShowMediaHeader_iconRemove__1sU63 {
    position: absolute;
    top: 10px;
    right: 3px;
    width: 30px;
    cursor: pointer; }
    .ShowMediaHeader_mediaView__2CkRD .ShowMediaHeader_iconRemove__1sU63 i {
      font-size: 30px;
      color: #b71c1c; }
      .ShowMediaHeader_mediaView__2CkRD .ShowMediaHeader_iconRemove__1sU63 i:active {
        opacity: 0.5; }

.App {
  width: 100%;
  height: 100vh; }

.react-datepicker-wrapper {
  flex: 1 1; }

.react-datepicker__input-container {
  width: 100%;
  display: flex; }

.react-datepicker-popper {
  z-index: 50; }

.ant-notification {
  z-index: 6666 !important; }

