@import '../../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;
  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .info {
    border: 1px solid $light-grey;
    padding: 20px 10px;
    margin-bottom: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    .title {
      grid-column: 1 / span 2;
    }
    .typeIsChat {
      grid-column: 2 / span 1;
    }
    .textareaItem {
      grid-column: 1 / span 2;
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 10px;

      > span {
        width: 150px;
        min-width: 150px;
        text-align: center;
        margin-top: 10px;
      }
      .textContainer {
        flex: 1;
        height: 100px;
        padding: 10px 20px;
        border: 1px solid $light-grey;
        overflow-y: auto;
        word-break: break-all;
      }
    }
  }

  .inputItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;

    > span {
      width: 150px;
      min-width: 150px;
      text-align: center;
    }

    input {
      flex: 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid $light-grey;

      &:disabled {
        background-color: #eeeeee;
      }
    }

    .select {
      height: 40px;
      flex: 1;
      span {
        width: 0px;
        background-color: #ffffff;
      }
    }

    .datepicker {
      flex: 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid $light-grey;
    }
  }
  .textReview {
    padding-left: 150px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .note {
      display: flex;
      margin-right: 10px;
    }
    .title {
      display: inline;
      margin-right: 10px;
      font-weight: bold;
    }
  }

  .logoItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 10px;

    span {
      margin-top: 10px;
      width: 150px;
      text-align: center;
    }
    .logoContainer {
      flex: 1;

      .file {
        display: none;
      }
      .upload {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btnUpload {
          flex: 1;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: $white;
          background-color: $primary;
          align-items: center;
          border: 1px solid $primary;
          margin-bottom: 10px;
          cursor: pointer;

          .icon {
            margin-right: 5px;
          }

          &:active {
            background-color: $white;
            color: $primary;
          }
        }
      }

      span {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .questionContainer {
    border: 1px solid $light-grey;
    padding: 20px;
    margin-bottom: 20px;
    cursor: initial;
    .questionTitle {
      display: flex;
      align-items: center;
      margin-bottom: 0.5em;
      > h2 {
        margin-right: 10px;
        margin-bottom: 0px;
      }
      > span {
        cursor: pointer;
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 25px;
        color: $danger;

        &:active {
          opacity: 0.5;
        }
      }
    }
    .qtypeContainer {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .qtypeRadio {
        flex: 1;
        display: flex;
        align-items: center;
        height: 30px;
        cursor: pointer;
        white-space: nowrap;

        input {
          margin-right: 10px;
        }
      }
    }

    .inputItem {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px;

      > span {
        width: 150px;
        text-align: left;
      }

      input {
        flex: 1;
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
        border: 1px solid $light-grey;

        &:disabled {
          background-color: #eeeeee;
        }
      }
    }
    .mediaContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px;

      span {
        width: 150px;
        margin-top: 10px;
      }

      .localMedia {
        .file {
          display: none;
        }
        .upload {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .btnUpload {
            width: 300px;
            height: 40px;
            border-radius: 1px;
            font-size: 16px;
            line-height: 40px;
            font-weight: 600;
            text-align: center;
            color: $white;
            background-color: $primary;
            align-items: center;
            border: 1px solid $primary;
            cursor: pointer;

            &:active {
              background-color: $white;
              color: $primary;
            }
          }
        }
        .mediaView {
          margin-top: 10px;
        }

        .nomedia {
          width: 100%;
          margin-top: 10px;
        }
      }

      .or {
        margin: 0px 20px;
        margin-top: 10px;
        font-weight: bold;
      }

      .youtubeMedia {
        .btnUpload {
          width: 300px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          text-align: center;
          color: $white;
          background-color: $primary;
          align-items: center;
          border: 1px solid $primary;
          cursor: pointer;

          &:active {
            background-color: $white;
            color: $primary;
          }
        }
        .youtubeView {
          margin-top: 10px;
        }
      }
    }

    .userMediaOptionsContainer {
      display: flex;
      align-items: center;
    }

    .userMediaRequired,
    .userMediaShowHide {
      height: 40px;
      display: flex;
      align-items: center;

      span {
        margin-right: 20px;
      }
    }

    .userMediaRequired {
      margin-left: 20px;
    }

    .answerContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px;

      span {
        width: 150px;
        margin-top: 10px;
      }

      div {
        flex: 1;

        .answerItem {
          display: flex;
          align-items: center;
          justify-content: space-between;

          input {
            flex: 1;
            height: 40px;
            font-size: 16px;
            padding: 0 20px;
            border: 1px solid $light-grey;
            margin-bottom: 10px;

            &:disabled {
              background-color: #eeeeee;
            }
          }
          span {
            width: 30px;
            margin: 0 5px 10px 5px;
            text-align: right;
            i {
              font-size: 24px;
              color: $danger;

              &:active {
                opacity: 0.5;
              }
            }
          }
        }

        .btnAdd {
          width: 100px;
          height: 40px;
          border-radius: 1px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 600;
          color: $primary;
          background-color: $white;
          border: 1px solid $primary;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          i {
            margin-right: 5px;
          }

          &:active {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }

    .btnAddMore {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: $primary;
      margin-bottom: 20px;
      cursor: pointer;

      &:active {
        opacity: 0.5;
      }
    }
  }

  .btnGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 20px;

    .btn {
      width: 100px;
      margin: 0px 10px;
    }
  }
}
.clipListItemDragging {
  background-color: #eeeeee;
}
