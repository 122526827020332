.wrapper {
  width: 100%;
  height: 100vh;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #03a9f4;
    color: white;
    padding: 16px 24px;
    font-size: 24px;
    z-index: 5555 !important;
    position: fixed;
    width: 100%;
    top: 0px;
    .logoutBtn {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;

      i {
        margin-right: 5px;
      }

      &:active {
        opacity: 0.5;
      }
    }
  }
  .container {
    height: 100%;
    display: flex;

    .sidebar {
      width: 200px;
      padding: 16px;
      position: fixed;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;
      overflow-y: auto;
      height: calc(100% - 68px);
      top: 68px;
      z-index: 10;
      .menuitem {
        display: flex;
        flex-direction: column;
        padding: 16px 0px;
        color: #757575;
        text-decoration: none;
        font-size: 18px;

        &:active {
          opacity: 0.5;
        }

        .submenuitem {
          color: #757575;
          text-decoration: none;
          font-size: 18px;
          margin-top: 32px;
          margin-left: 16px;
        }
      }

      .menuitem-selected {
        color: #555555;
        font-weight: bold;
      }

      .menucategory {
        color: #aaaaaa;

        &:active {
          opacity: 1;
        }
      }
    }

    .content {
      width: calc(100% - 200px);
      margin-top: 68px;
      margin-left: 200px;
    }
  }
}
