.wrapper {
  padding: 20px;
  margin-bottom: 20px;
  z-index: 1;
  .columns {
    flex: 1;
    padding-left: 20px;
    min-width: 50%;
    .typeReport {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600;
    }
    .questionTitle {
      font-size: 16px;
      font-weight: 400;
    }
    .subTypeReport {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
}