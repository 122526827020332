@import '../../../theme/colors.scss';

.wrapper {
  .searchbar {
    flex: 1;
    height: 40px;
    border-radius: 20px;
    background-color: $white;
    border: 1px solid $primary;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-bottom: 10px;
    &:active {
      background-color: $white;
    }

    .iconSearch {
      color: $primary;
      font-size: 18px;
      margin-right: 5px;
    }

    input {
      width: 100%;
      height: 40px;
      font-size: 16px;
      border-style: none;

      &:focus {
        outline: none;
      }
    }
  }
}
