.wrapper {
  .button {
    max-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: unset !important;
    }
  }
}
.wrapperModal {
  .wrapperLayout {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    .checkboxSection {
      display: flex;
      flex-direction: column;
      .checkbox {
        margin-left: 0px;
        padding-left: 15px;
        .name {
          font-size: 16px;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
  }
}
