@import '../../../../theme/colors.scss';

.videoDownload {
  display: flex;
  flex-direction: column;
  align-items: center;
  > button {
    margin-top: 10px;
    width: 100%;
    max-width: 150px;
  }
}

