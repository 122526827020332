.se-placeholder,
.sun-editor-editable *,
.se-wrapper-inner {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-variant: tabular-nums;
  -webkit-font-smoothing: antialiased;
}
.se-placeholder {
  font-size: 16px !important;
}
.sun-editor-editable {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}
.se-dialog {
  .se-dialog-tabs {
    display: none;
  }
  .se-dialog-form {
    .se-dialog-form-files {
      > input {
        height: auto !important;
        padding: 3px !important;
      }
    }
    .se-dialog-files-edge-button {
      display: none;
    }
    .se-link-preview {
      display: none !important;
    }
  }
  .se-dialog-image {
    ._se_tab_content {
      .se-dialog-body {
        > div:first-child {
          border-width: 0px !important;
        }
        > .se-dialog-form {
          &:nth-child(2),
          &:nth-child(4) {
            display: none !important;
          }
        }
      }
    }
  }
  .se-dialog-body {
    > .se-dialog-form-footer > label:nth-child(2) {
      display: none !important;
    }
  }
  .se-dialog-image {
    ._se_tab_content {
      .se-dialog-body {
        > div:first-child {
          border-width: 0px !important;
        }
        > .se-dialog-form {
          &:nth-child(2),
          &:nth-child(4) {
            display: none !important;
          }
        }
      }
    }
  }
  .se-dialog-body {
    > .se-dialog-form-footer > label:nth-child(2) {
      display: none !important;
    }
  }
}
