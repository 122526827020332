@import '../../../../theme/colors.scss';

.selected {
  background-color: #3297fd;
  color: white;
}

.hovered {
  background-color: $light-grey;
  opacity: 0.7;
  color: black;
}
