@import '../../../../theme/colors.scss';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .createClipBtn {
    width: 150px;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  .clearButton {
    width: 100px;
  }

  .userSummaryTranscription {
    padding: 16px 16px 0px 16px;
  }
}

.noTranscriptWrapper {
  padding: 16px;
  margin-bottom: 0px;
  color: $danger;
}