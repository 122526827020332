.modal {
  .modalTitle {
    font-weight: bold;
    font-size: 16px;
    margin-left: 8px;
  }
  .modalWrapperContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 400px;
    overflow-y: auto;

    .modalContent {
      display: flex;
      margin: 24px 12px 0 12px;

      .avatar {
        width: 36px;
        margin-right: 16px;
        margin-top: 2px;
      }

      .username {
        font-weight: bold;
        font-size: 12px;
      }

      .dateTime {
        font-size: 12px;
        color: gray;
      }

      .messageGroup {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .wrapperMessage {
        display: flex;
        gap: 32px;

        .wrapperVideo {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }

  .modalFooter {
    margin: 12px 20px;

    .wrapperCheckbox {
      display: flex;
      margin: 12px 0;

      .requireVideoCheckbox {
        margin-right: 16px;
      }
    }

    .markRejected {
      color: #fff;
      border-color: #ff4d4f;
      background: #ff4d4f;
    }
  }
}
