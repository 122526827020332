@import '../../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;

  .top {
    display: flex;
    margin-bottom: 1px;

    .searchbar {
      flex: 1;
      height: 40px;
      border-radius: 20px;
      background-color: $white;
      border: 1px solid $primary;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      margin-bottom: 10px;
      &:active {
        background-color: $white;
      }

      .iconSearch {
        color: $primary;
        font-size: 18px;
        margin-right: 5px;
      }

      input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-style: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .header {
    background: $primary;
  }

  th {
    height: 40px;
    padding: 10px 10px;
    color: $white;
  }

  td {
    padding: 10px 10px;
  }
  table,
  th,
  td {
    border: 1px solid $light-grey;
    text-align: center;
  }

  .campaign {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }

  .selector {
    width: 500px;
    display: flex;
    flex-direction: row;
    padding: 4px;
    margin-bottom: 15px;
    span {
      flex: 1;
      padding: 6px;
    }
    .select {
      flex: 3;
    }
  }

  h3 {
    margin: 8px;
  }

  .select {
    span {
      background-color: #ffffff;
    }
  }
}
