.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .clipResultItem {
    text-align: center;
    width: calc(33% - 20px);
    margin: 10px;

    .clipVideo {
      width: 100%;
      height: 300px;
      background-color: lightgrey;
    }
    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
      }
      > button {
        width: 150px;
      }
    }
  }
}
