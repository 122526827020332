.App {
  width: 100%;
  height: 100vh;
}

.react-datepicker-wrapper {
  flex: 1;
}

.react-datepicker__input-container {
  width: 100%;
  display: flex;
}
.react-datepicker-popper {
  z-index: 50;
}

.ant-notification {
  z-index: 6666 !important;
}