@import "../../../theme/colors.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .searchbar {
      flex: 1;
      height: 40px;
      border-radius: 20px;
      background-color: $white;
      border: 1px solid $primary;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;

      &:active {
        background-color: $white;
      }

      .iconSearch {
        color: $primary;
        font-size: 18px;
        margin-right: 5px;
      }

      input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-style: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .actionDelete {
    margin: 0px 5px;
    color: $danger;

    &:active {
      opacity: 0.5;
    }
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
