@import '../../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;
  width: 100%;
  height: 100%;
  .spin {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h2 {
      flex: 1;
      margin-bottom: 0px;
      margin-right: 10px;
    }

    .headerBtnGroup {
      display: flex;
      align-items: center;

      .headerBtn {
        width: 150px;
        margin: 0px 5px;
      }
    }

    .markCompleted {
      width: 150px;
      height: 32px;
      line-height: 30px;
      color: #ffffff;
      background-color: #1890ff;
      border-color: #1890ff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      border: 1px solid transparent;
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      padding: 0 15px;
      font-size: 14px;
      border-radius: 4px;
    }

    .markRejected {
      width: 150px;
      height: 32px;
      line-height: 30px;
      color: #ffffff;
      background-color: #ff4d4f;
      border-color: #ff4d4f;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      border: 1px solid transparent;
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      padding: 0 15px;
      font-size: 14px;
      border-radius: 4px;
    }
  }

  .questionContainer {
    border: 1px solid $light-grey;
    padding: 20px;
    margin-bottom: 20px;

    .media {
      width: 400px;
    }

    .typeReport {
      display: flex;
      justify-content: left;
      align-items: baseline;
      font-size: 18px;
      font-weight: 600;
    }
    .questionTitle {
      font-size: 16px;
      font-weight: 400;
    }

    .answerContainer {
      margin-top: 20px;

      .answerItem {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
          margin-left: 5px;
        }
      }
    }

    .userMediaContainer {
      display: flex;
      margin-top: 20px;
      overflow: auto;
      width: 100%;
      padding-bottom: 10px;
      .overflow {
        margin-top: 3px;
        margin-left: 3px;
        display: flex;
        .userMedia {
          height: 150px;
          margin-right: 10px;
          width: 250px;
          overflow: auto;
          box-shadow: $boxShadow;
        }
        .userMediaSignature {
          height: 150px;
          width: 250px;
          overflow: auto;
          box-shadow: $boxShadow;
          object-fit: contain;
        }
        .videoGroup {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 10px;

          .dateTime {
            align-self: flex-start;
            margin-bottom: 4px;
            font-size: 12px;
            color: gray;
          }
          > button {
            margin-top: 10px;
            width: 100%;
            max-width: 150px;
          }
          > video {
            margin: auto;
          }
        }
      }
    }

    .feedbackContainer {
      margin-top: 20px;
      .feedbackContainerTitle {
        display: flex;
      }

      h4 {
        margin-bottom: 0px;
        margin-right: 10px;
      }

      .feedback {
        width: 100%;
        font-size: 16px;
        padding: 5px 10px;
        border: 1px solid $light-grey;
        margin-top: 10px;
      }
    }
  }

  .btnGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 50px;

    .btn {
      width: 100px;
      margin: 0px 10px;
    }
  }
}
