@import '../../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;

  .top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;

    .searchContainer {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .searchbar {
        flex: 1;
        height: 40px;
        border-radius: 20px;
        background-color: $white;
        border: 1px solid $primary;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;

        &:active {
          background-color: $white;
        }

        .iconSearch {
          color: $primary;
          font-size: 18px;
          margin-right: 5px;
        }

        input {
          width: 100%;
          height: 40px;
          font-size: 16px;
          border-style: none;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .searchfilters {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .filter {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        cursor: pointer;

        input {
          margin-right: 5px;
        }
      }
      .moreSpace {
        width: 150px;
        visibility: hidden;
      }
    }

    .btnAdd {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $primary;
      align-items: center;
      border: 1px solid $primary;
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }

      &:focus {
        outline: none;
      }
      &:active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .header {
    background: $primary;
  }

  th {
    height: 40px;
    padding: 10px 10px;
    color: $white;
  }

  td {
    padding: 10px 10px;
  }

  .name {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }
}
