@import '../../../theme/colors.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 0 50px;
  min-height: 100%;
  min-width: 100%;
  background: #f0f2f5;
  overflow: hidden;
  @media (max-width: 576px) {
    padding: 0 20px;
  }
  .layout {
    @media (max-width: 576px) {
      width: 100%;
    }
    width: 80%;
    background-color: $white;
    .header {
      background-color: $primary;
      min-height: 100px;
      height: auto;
      font-size: 36px;
      color: $white;
      display: flex;
      justify-content: center;
      line-height: normal;
      align-items: center;
      @media (max-width: 768px) {
        font-size: 25px;
        padding: 0 20px;
      }
      .text {
        display: block;
      }
    }

    .content {
      padding: 1.5em 30px 2em;
      .completeCampaign {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > i {
          font-size: 30px;
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
        }
      }
      .expireTime {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .alert {
        margin-bottom: 20px;
      }
      .action {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        button:not(:first-of-type) {
          margin-left: 10px;
        }
        .backBtn {
          background: #d9d9d9;
          &:hover, &:active, &:focus {
            color: #262626;
            border-color: #8c8c8c;
          }
        }
      }

      .progress {
        display: flex;
        justify-content: center;
        flex: 1;
        .inner {
          width: 300px;
          margin-top: 10px;
        }
      }
      .errInfoReject {
        display: flex;
        span {
          max-width: 300px;
          width: 300px;
          margin: auto;
          color: red;
          font-weight: 600;
          display: block;
          text-align: center;
        }
      }

      .answerUser {
        max-width: 300px;
        margin: auto;
        .logo {
          display: flex;
          justify-content: center;
          img {
            width: 250px;
            max-height: 250px;
            object-fit: contain;
            box-shadow: $boxShadow;
            @media (max-width: 576px) {
              width: 100%;
            }
          }
        }
        .title {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: justify;
        }
        .shareYour {
          color: $grey;
          margin-bottom: 10px;
          display: block;
        }
      }

      .answerStep {
        .question {
          display: flex;
          flex-direction: column;
          align-items: center;
          .typeQuestion {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            .questionTitle {
              font-weight: 500;
              font-size: 25px;
              min-width: 300px;
              max-width: 300px;
              text-align: start;
              word-break: break-word;
            }
            .type {
              text-align: left;
              color: $grey;
              max-width: 300px;
              width: 100%;
              display: block;
              margin-top: 10px;
            }
            .select {
              line-height: 20px;
              display: flex;
              align-items: baseline;
              margin-top: 5px;
              cursor: default;
              &:first-of-type {
                margin-top: 15px;
              }
              input[type='checkbox'] {
                transform: scale(1.25);
                margin-right: 10px;
                min-width: 16px;
              }
              span {
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
          .youtubeView {
            position: relative;

            .media {
              width: 300px;
              margin-top: 10px;
            }

            div {
              position: absolute;
              top: 10px;
              right: 0px;
              width: 30px;

              i {
                font-size: 30px;
                color: $danger;

                &:active {
                  opacity: 0.5;
                }
              }
            }
          }

          .mediaView {
            position: relative;
            @media (max-width: 576px) {
              width: 100%;
            }
            .media {
              height: 200px;
              width: 300px;
              object-fit: contain;
              box-shadow: $boxShadow;
              margin: 10px 5px 0 5px;
              display: flex;
              justify-content: center;
              align-items: center;

              @media (max-width: 576px) {
                width: 100%;
              }
            }
            .iconRemove {
              position: absolute;
              top: 10px;
              right: 3px;
              width: 30px;
              cursor: pointer;

              i {
                font-size: 30px;
                color: $danger;

                &:active {
                  opacity: 0.5;
                }
              }
            }
          }

          .upload {
            margin-top: 10px;
            .btnUpload {
              height: 40px;
              border-radius: 3px;
              background-color: $primary;
              width: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .icon {
                margin-right: 5px;
              }

              &:active {
                background-color: $white;
                color: $primary;
              }
            }
            .img {
              object-fit: cover;
              width: 40px;
              height: 30px;
            }
          }

          .nomedia {
            margin-top: 10px;
          }

          .mediaRow {
            display: flex;
          }

          .buttonSelected {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            .btnOption {
              width: 300px;
              height: 35px;
              flex: 1;
              border-radius: 2px;
              line-height: 40px;
              font-weight: 600;
              text-align: start;
              margin-top: 10px;
              &.active {
                background-color: #40a9ff;
              }
              .rowRanking {
                display: flex;
                justify-content: space-between;
              }
            }
          }
          .feedback {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 10px;
            .feedbackTitle {
              max-width: 300px;
              width: 300px;
              color: red;
              font-weight: 600;
              font-size: 18px;
            }
            span {
              max-width: 300px;
              width: 300px;
              display: block;
            }
          }
          .textarea {
            resize: none;
            width: 300px;
            max-width: 300px;
            margin-top: 10px;
            color: $dark;
            @media (max-width: 576px) {
              width: 100%;
            }
          }

          .answer {
            display: flex;
            flex-direction: column;
            min-width: 300px;
            max-width: 300px;
            .title {
              font-weight: 500;
            }
          }
          .shareYour {
            color: $primary;
            display: block;
          }
        }
      }
    }
  }
}
