@import '../../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;

  .logo {
    width: 250px;
    margin-bottom: 20px;
  }

  .active {
    color: #1890ff;
  }

  .inactive {
    color: #ff4d4f;
  }

  .btnDownload {
    width: 150px;
    margin-right: 10px;
  }

  .downloadContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btnCancel {
    width: 100px;
  }

  .spinLoader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }

  .selector {
    display: flex;
    flex-direction: row;
    padding: 4px;
    z-index: 2;
    span {
      padding: 6px;
      width: 100px;
    }
    .select {
      flex: 1;
      max-width: 400px;
      padding-right: 10px;
      span {
        background-color: #ffffff;
      }
    }
  }
  .userMediaContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .videoDownload {
      display: flex;
      flex-direction: column;
      align-items: center;
      > button {
        margin-top: 10px;
        width: 100%;
        max-width: 150px;
      }
    }
    .userMedia {
      height: 150px;
      width: 250px;
      margin-left: 10px;
      margin-right: 10px;
      overflow: auto;
      object-fit: contain;
      box-shadow: $boxShadow;
      margin-top: 10px;
    }
  }
  .cellHeight {
    height: 1px;
    vertical-align: top;
    .transcriptionText {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .questionContainer {
    padding: 20px;
    margin-bottom: 20px;
    z-index: 1;

    .feedbackContainer {
      margin-top: 20px;

      h4 {
        margin-bottom: 0px;
        margin-right: 10px;
      }

      .feedback {
        width: 100%;
        font-size: 16px;
        padding: 5px 10px;
        border: 1px solid $light-grey;
        margin-top: 10px;
      }
    }
  }
  .subHeader {
    display: flex;
  }

  .answered {
    font-weight: bold;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
