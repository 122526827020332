@import '../../../../theme/colors.scss';

.videoWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table {
  .clipTitle {
    font-size: 18px;
    color: $grey;
    margin-bottom: 0px;
  }
  thead > tr > th {
    font-size: 18px;
    color: $grey;
    font-weight: bold;
  }
}
