@import '../../theme/colors.scss';

.wrapper {
  .mediaView {
    position: relative;
    margin: auto;
    @media (max-width: 576px) {
      width: 100%;
    }
    .media {
      object-fit: contain;
      box-shadow: $boxShadow;

      @media (max-width: 576px) {
        width: 100%;
      }
    }
    .iconRemove {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 30px;
      cursor: pointer;

      i {
        font-size: 30px;
        color: $danger;

        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}