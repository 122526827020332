@import '../../../theme/colors.scss';
.wrapper {
  .webcam {
    video {
      box-shadow: $boxShadow;
      width: 100%;
      transform: scale(-1, 1);
    }
  }

  .capture {
    display: flex;
    flex-direction: column;
    .actionCapture {
      display: flex;
      flex-direction: row;
    }
    .capturePhoto {
      margin-left: 10px;
    }
  }
}
