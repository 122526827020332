@import '../../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
    .btnBack {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $primary;
      align-items: center;
      border: 1px solid $primary;
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }

      &:focus {
        outline: none;
      }
      &:active {
        background-color: $primary;
        color: $white;
      }
    }
  }
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .name {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 'bold';
  }
  .email {
    margin-top: 10px;
  }

  .socialContainer {
    width: calc(100% - 40px);
    padding: 20px;
    border: 1px solid $light-grey;
    margin-top: 20px;

    .socialItem {
      margin: 10px 0px;

      i {
        width: 30px;
        font-size: 18px;
        margin-right: 10px;
      }

      a {
        color: $primary;
      }

      span {
        color: $danger;
      }
    }
  }

  .detailsContainer {
    width: calc(100% - 40px);
    padding: 20px;
    border: 1px solid $light-grey;
    margin-top: 20px;

    .detailsItem {
      display: flex;
      margin-bottom: 10px;

      span {
        width: 100px;
        font-size: 14px;
        color: $grey;
      }

      .detailsInfo {
        flex: 1;
        font-size: 16px;
      }
    }
  }

  .featuredVideoContainer {
    width: calc(100% - 40px);
    padding: 20px;
    border: 1px solid $light-grey;
    margin-top: 20px;

    video {
      width: 300px;
      height: 200px;
      margin-top: 10px;
    }

    span {
      margin-top: 10px;
      color: $danger;
    }
  }

  .btnBack {
    width: 100px;
    margin-top: 20px;
  }
}
