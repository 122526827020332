@import "../../theme/colors.scss";

.backBtn {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: $primary;
  align-items: center;
  border: 1px solid $primary;
  cursor: pointer;
  margin-left: 10px;

  .icon {
    margin-right: 5px;
  }

  &:focus {
    outline: none;
  }
  &:active {
    background-color: $primary;
    color: $white;
  }
}
