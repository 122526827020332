@import '../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;

  .actionBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;

    .btnAdd {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $primary;
      align-items: center;
      border: 1px solid $primary;
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }

      &:focus {
        outline: none;
      }
      &:active {
        background-color: $primary;
        color: $white;
      }
    }

  }

  .action {
    display: flex;
    justify-content: center;

    .actionBtn {
      margin: 0 10px;
      cursor: pointer;
      color: black;
      font-size: 17px;

      &:hover {
        color: blue;
      }
    }
  }

  .input {
    margin: 5px 0;
  }

}
