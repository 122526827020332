@import '../../../theme/colors.scss';
@import '../style/SymmaryReport.module.scss';

.wrapper {
  .columns {
    .tableSummary {
      width: 50%;
      margin: auto;
    }
  }

  .answerContainer {
    margin-top: 20px;

    .answerItem {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        margin-left: 5px;
      }
    }
  }

  .userMediaContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    flex-wrap: wrap;
    .videoDownload {
      display: flex;
      flex-direction: column;
      align-items: center;
      > button {
        margin-top: 10px;
        width: 100%;
        max-width: 150px;
      }
    }
    .userMedia {
      height: 150px;
      width: 250px;
      margin-left: 10px;
      margin-right: 10px;
      overflow: auto;
      object-fit: contain;
      box-shadow: $boxShadow;
      margin-top: 10px;
    }
  }

  .feedbackContainer {
    margin-top: 20px;

    h4 {
      margin-bottom: 0px;
      margin-right: 10px;
    }

    .feedback {
      width: 100%;
      font-size: 16px;
      padding: 5px 10px;
      border: 1px solid $light-grey;
      margin-top: 10px;
    }
  }
}
