@import '../../../theme/colors.scss';

.wrapper {
  padding: 20px;
  font-size: 16px;
  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .basicTableContainer {
    padding: 20px 10px;
    border: 1px solid $light-grey;

    .basicTable {
      width: 100%;

      tr {
        display: flex;
        justify-content: space-between;
      }

      td {
        flex: 1;
      }
    }
  }

  .inputItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;

    span {
      width: 150px;
      text-align: center;
    }

    input {
      flex: 1;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      border: 1px solid $light-grey;

      &:disabled {
        background-color: #eeeeee;
      }
    }

    .select {
      height: 40px;
      flex: 1;

      span {
        width: 0px;
        background-color: #ffffff;
      }
    }
  }

  .videoTableContainer {
    margin-top: 50px;
    width: 100%;

    table {
      width: 100%;
      padding: 20px 10px;
      border: 1px solid $light-grey;
      border-collapse: collapse;

      tr {
        justify-content: space-between;
        border: 1px solid $light-grey;
      }
      th {
        border: 1px solid $light-grey;
        height: 50px;
        line-height: 50px;
      }
      td {
        flex: 1;
        border: 1px solid $light-grey;
        text-align: center;
        padding: 10px;
      }

      .select {
        input[type='checkbox'] {
          transform: scale(1.5);
        }
      }

      video {
        width: 300px;
        height: 200px;
      }

      .progress {
        font-weight: 400;
        color: $danger;
        .action {
          display: flex;
          align-items: center;
          flex-direction: column;
          button {
            width: 150px;
          }
        }
      }

      .completed {
        font-weight: 400;
        color: $primary;
        .action {
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            width: 150px;
          }
        }
      }
    }
  }

  .transcriptionContainer {
    width: 100%;
    margin-top: 50px;

    .transcriptionTable {
      width: 100%;
      margin-top: 25px;

      table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid $light-grey;

        tr,
        td {
          border: 1px solid $light-grey;
        }

        td {
          height: 30px;
          padding: 0px 5px;
          cursor: pointer;

          &:active {
            opacity: 0.5;
          }

          .time {
            margin-top: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 8px;
          }
        }

        .selectedword {
          background-color: $primary;
        }
      }
    }
  }

  .btnGroup {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .btnSave,
    .btnSearch {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $primary;
      align-items: center;
      border: 1px solid $primary;
      cursor: pointer;
      margin: 0px 25px;

      &:active {
        background-color: $primary;
        color: $white;
      }
    }

    .btnClear {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $danger;
      align-items: center;
      border: 1px solid $danger;
      cursor: pointer;
      margin: 0px 25px;

      &:active {
        background-color: $danger;
        color: $white;
      }
    }
  }

  .clipListContainer {
    width: 100%;

    .clipList {
      padding: 15px;
      background-color: $light-grey;
    }

    .clipListItem {
      padding: 15px;
      background-color: #ffffff;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .clipListItemDragging {
      background-color: #eeeeee;
    }
  }

  .clipResultContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .clipResultItem {
      text-align: center;
      width: calc(33% - 20px);
      margin: 10px;

      .clipVideo {
        width: 100%;
        height: 300px;
        background-color: lightgrey;
      }
      .action {
        display: flex;
        flex-direction: column;
        align-items: center;
        > span {
          margin-top: 20px;
          font-size: 20px;
          font-weight: bold;
        }
        > button {
          width: 150px;
        }
      }
    }
  }
}
