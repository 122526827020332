@import '../../../../theme/colors.scss';

.userSummaryMedia {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .userMedia {
    height: 150px;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow: auto;
    object-fit: contain;
    box-shadow: $boxShadow;
    margin-top: 10px;
  }

  .mediaWraper {
    display: flex;
    flex-direction: column;

    .dateTime {
      align-self: flex-start;
      margin-bottom: 4px;
      font-size: 12px;
      color: gray;
    }     
  }
}
