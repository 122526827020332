@import '../../../../theme/colors.scss';

$borderColor: #f0f0f0;
$backgroundColor: #fafafa;

.userSummaryTable {
  border: 1px solid $borderColor;
  margin-bottom: 16px;

  .userName,
  .media,
  .textTranscript {
    padding: 16px;
    margin-bottom: 0px;
    border-bottom: 1px solid $borderColor;
  }
  .textAnswer,
  .answer {
    padding: 16px;
    margin-bottom: 0px;
    border-left: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
  }
  .userName,
  .textAnswer,
  .textTranscript {
    background-color: $backgroundColor;
  }
  .headerTitle {
    font-size: 18px;
    font-weight: bold;
    color: $grey;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .flexCol {
    display: flex;
    flex-direction: column;
    .grow {
      flex-grow: 1;
    }
  }
}
