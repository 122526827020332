@import '../../../theme/colors.scss';
.youtubeView {
  position: relative;

  .media {
    width: 300px;
    margin-top: 10px;
  }

  div {
    position: absolute;
    top: 10px;
    right: 0px;
    width: 30px;

    i {
      font-size: 30px;
      color: $danger;

      &:active {
        opacity: 0.5;
      }
    }
  }
}

.mediaView {
  position: relative;
  @media (max-width: 576px) {
    width: 100%;
  }
  .media {
    height: 200px;
    width: 300px;
    object-fit: contain;
    box-shadow: $boxShadow;
    margin: 10px 5px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .iconRemove {
    position: absolute;
    top: 10px;
    right: 3px;
    width: 30px;
    cursor: pointer;

    i {
      font-size: 30px;
      color: $danger;

      &:active {
        opacity: 0.5;
      }
    }
  }
}
