@import '../../../theme/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .container {
    border: 1px solid $light-grey;
    padding: 20px;
    margin-bottom: 50px;

    h2 {
      font-weight: 600;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .iconRemove {
        font-size: 32px;
        color: $danger;

        &:active {
          opacity: 0.5;
        }
      }
    }

    .btnAddMore {
      font-size: 18px;
      text-decoration: underline;
      font-weight: 600;
      color: $primary;
      cursor: pointer;

      &:active {
        opacity: 0.5;
      }
    }
  }

  .inputItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;

    span {
      width: 200px;
    }
    input {
      height: 40px;
      width: 300px;
      font-size: 16px;
      padding: 0 20px;
    }

    .codeAction {
      width: 260px;
      display: flex;
      justify-content: space-between;
      button {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 12px;
        line-height: 40px;
        font-weight: 600;
        box-shadow: none;
      }
      button:first-of-type {
        margin: 0 10px;
      }
    }

    .file {
      display: none;
    }
    .participantBtnGroup {
      width: 342px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        flex: 1;
        height: 40px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 40px;
        font-weight: 600;
        text-align: center;
        align-items: center;
        cursor: pointer;
      }
      .btnPrimary {
        @extend .btn;

        color: $primary;
        border: 1px solid $primary;
        margin-right: 10px;
        text-decoration: none;

        &:active {
          background-color: $primary;
          color: $white;
        }
      }
      .btnPrimaryDisabled {
        @extend .btn;
        color: $light-grey;
        border: 1px solid $light-grey;
        margin-right: 10px;
      }

      .btnDanger {
        @extend .btn;

        color: $danger;
        border: 1px solid $danger;

        &:active {
          background-color: $danger;
          color: $white;
        }
      }
      .btnDangerDisabled {
        @extend .btn;
        color: $light-grey;
        border: 1px solid $light-grey;
      }
    }

    .nameInput {
      width: 207px;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      margin-right: 10px;
    }

    .emailInput {
      width: 207px;
      height: 40px;
      font-size: 16px;
      padding: 0 20px;
      margin-right: 10px;
    }

    .btnAdd {
      width: 105px;
      height: 40px;
      border-radius: 5px;
      font-size: 12px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      align-items: center;
      color: $primary;
      border: 1px solid $primary;
      margin-right: 10px;
      cursor: pointer;

      &:active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .btnGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;

    .btnSave {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $primary;
      align-items: center;
      border: 1px solid $primary;
      cursor: pointer;
      margin-right: 50px;

      &:active {
        background-color: $primary;
        color: $white;
      }
    }

    .btnCancel {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $danger;
      align-items: center;
      border: 1px solid $danger;
      cursor: pointer;

      &:active {
        background-color: $danger;
        color: $white;
      }
    }
  }

  table {
    border-collapse: collapse;
    margin-bottom: 10px;
  }

  th {
    padding: 10px 10px;
    background-color: #eee;
  }

  td {
    padding: 10px 10px;
  }
  table,
  th,
  td {
    border: 1px solid $light-grey;
    text-align: center;
  }
}
