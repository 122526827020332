@import '../../../theme/colors.scss';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;

  .messagePane {
    flex: 1;
    padding: 20px;
    overflow: auto;

    .messageContainer {
      display: flex;
      margin-bottom: 10px;

      .messageItem {
        display: flex;
        flex-direction: row;
        width: 40%;
        justify-content: flex-end;

        .adminAvatar {
          margin-right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden;
        }
        .userAvatar {
          margin-left: 10px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden;
        }
        .messageBody {
          flex: 1;
          display: flex;
          flex-direction: column;
          font-size: 18px;
          line-height: 20px;
          padding: 5px 10px;
          border-radius: 5px;
          word-break: break-word;
          .messageVideo {
            width: calc(100%-20px);
            height: 200px;
            margin: 10px;
          }
          .messageImage {
            width: 90%;
            height: 200px;
            margin: 10px;
            object-fit: contain;
          }
          span {
            margin-top: 10px;
            font-size: 10px;
          }
        }

        .messageLBody {
          justify-content: flex-start;
          background-color: orange;
          span {
            text-align: left;
          }
        }
        .messageRBody {
          justify-content: flex-end;
          background-color: $light-grey;
          margin-right: 10px;
          text-align: left;

          span {
            text-align: right;
          }
        }
      }
    }

    .messageRContainer {
      justify-content: flex-end;
    }
    .messageLContainer {
      justify-content: flex-start;
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .inputContainer {
    // height: 60px;
    border-top: 1px solid $light-grey;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .actionBtn {
      margin-right: 10px;
      cursor: pointer;

      i {
        font-size: 24px;
        color: $primary;
      }

      &:active {
        opacity: 0.5;
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      background: #ffffff;
    }
    .menu-item {
      cursor: pointer;
      padding: 15px 20px;
      border-bottom: 1px solid rgb(187, 187, 187);
    }
    .menu-item:hover {
      color: $primary;
    }

    textarea,
    input {
      flex: 1;
      height: 30px;
      font-size: 16px;
      padding: 0px 10px;
    }

    .sendBtn {
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      margin-left: 10px;
      display: flex;

      &:active {
        background-color: $primary;
        color: $white;
      }
    }

    .file {
      display: none;
    }
  }

  .statusbar {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: darkgrey;
    margin-bottom: 10px;

    .clearBtn {
      padding: 5px 10px;
      font-size: 12px;
      color: $danger;
      font-weight: bold;
      cursor: pointer;

      i {
        margin-left: 5px;
      }

      &:active {
        opacity: 0.5;
      }
    }
  }
}
.wrapperSpin {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
