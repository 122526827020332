@import '../../../../theme/colors.scss';

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: text;

  .textDanger {
    color: $danger;
  }
}
