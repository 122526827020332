@import "../../../theme/colors.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .searchbar {
      flex: 1;
      height: 40px;
      border-radius: 20px;
      background-color: $white;
      border: 1px solid $primary;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      margin-right: 10px;

      &:active {
        background-color: $white;
      }

      .iconSearch {
        color: $primary;
        font-size: 18px;
        margin-right: 5px;
      }

      input {
        width: 100%;
        height: 40px;
        font-size: 16px;
        border-style: none;

        &:focus {
          outline: none;
        }
      }
    }

    .ml-10 {
      margin-left: 10px;
    }

    div {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      text-align: center;
      color: $primary;
      align-items: center;
      border: 1px solid $primary;
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }

      &:focus {
        outline: none;
      }
      &:active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .header {
    background: $primary;
  }

  th {
    height: 40px;
    padding: 10px 10px;
    color: $white;
  }

  td {
    padding: 10px 10px;
  }

  .org {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }
}
