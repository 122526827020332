@import '../../../theme/colors.scss';

.wrapper {
  width: 100%;

  .clipList {
    padding: 15px;
    background-color: $light-grey;
  }

  .clipListItem {
    padding: 15px;
    background-color: #ffffff;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .clipListItemDragging {
    background-color: #eeeeee;
  }
  .btnGroup {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .save {
      width: 100px;
      height: 40px;
      font-weight: 600;
    }
  }
}
