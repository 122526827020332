.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: rgba(211, 211, 211, 0.548);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  h2 {
    margin-bottom: 0px;
  }
  .avatar {
    text-transform: uppercase;
    &:nth-child(4n) {
      background-color: red;
    }
    &:nth-child(4n + 1) {
      background-color: orange;
    }
    &:nth-child(4n + 2) {
      background-color: purple;
    }

    &:nth-child(4n + 3) {
      background-color: blue;
    }
  }
}
