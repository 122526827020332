@import '../../../theme/colors.scss';

.wrapper {
  .mediaView {
    position: relative;
    width: 310px;
    margin: auto;
    @media (max-width: 576px) {
      width: 100%;
    }
    .media {
      height: 200px;
      width: 300px;
      object-fit: contain;
      box-shadow: $boxShadow;
      margin: 10px 5px 0 5px;

      @media (max-width: 576px) {
        width: 100%;
      }
    }
    .iconRemove {
      position: absolute;
      top: 10px;
      right: 3px;
      width: 30px;
      cursor: pointer;

      i {
        font-size: 30px;
        color: $danger;

        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}
