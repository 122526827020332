@import "../../theme/colors.scss";

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  .loginContainer {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: -100px;
    padding: 20px;
    border: 1px $grey;
    border-radius: 1px;
    box-shadow: 2px 3px 2px 3px $light-grey;

    h2 {
      text-align: center;
    }

    input {
      height: 40px;
      font-size: 16px;
      line-height: 40px;
      padding: 0px 10px;
      margin-bottom: 10px;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 150px;
      height: 40px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      font-weight: 600;
      margin-top: 10px;
      text-align: center;
      color: $white;
      align-items: center;
      border: 1px solid $primary;
      background-color: $primary;
      cursor: pointer;

      &:active {
        background-color: $white;
        color: $primary;
      }
    }
  }

  .spinLoader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
